import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpClient } from '@angular/common/http';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { PERFECT_SCROLLBAR_CONFIG } from 'ngx-perfect-scrollbar';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { AutofocusDirective } from './directives/autofocus.directive';
import { NotificationCenterComponent } from './notification-center/notification-center.component';
import { PickListModule } from 'primeng/picklist';
import { ReplacePipe } from '@app/shared/pipes/replace.pipe';
import { LoaderComponent } from './loader/loader.component';
import { NgxSpinnerModule } from 'ngx-spinner';
import { CustomTranslatePipe } from './pipes/translate.pipe';
import { IsPOCEnvironmentDirective } from './directives/is-poc-environment.directive';
import { RemoveGuidFieldPipe } from './pipes/remove-guid-field.pipe';

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true,
};

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

@NgModule({
  declarations: [
    AutofocusDirective,
    NotificationCenterComponent,
    ReplacePipe,
    CustomTranslatePipe,
    LoaderComponent,
    IsPOCEnvironmentDirective,
    RemoveGuidFieldPipe,
  ],
  imports: [
    CommonModule,
    TranslateModule.forChild(),
    NgbModule,
    FormsModule,
    ReactiveFormsModule,
    PerfectScrollbarModule,
    PickListModule,
    NgxSpinnerModule,
  ],
  exports: [
    CommonModule,
    TranslateModule,
    NgbModule,
    FormsModule,
    ReactiveFormsModule,
    PerfectScrollbarModule,
    AutofocusDirective,
    NotificationCenterComponent,
    PickListModule,
    ReplacePipe,
    CustomTranslatePipe,
    LoaderComponent,
    IsPOCEnvironmentDirective,
    RemoveGuidFieldPipe,
  ],
  providers: [
    {
      provide: PERFECT_SCROLLBAR_CONFIG,
      useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG,
    },
  ],
})
export class SharedModule {
  static forRoot(): ModuleWithProviders<SharedModule> {
    return {
      ngModule: SharedModule,
    };
  }
}
