const hostNameAPI: string = 'https://doit-demo.vnptit.vn/dmit/api';
const hostNameOrigin: string = new URL(hostNameAPI).origin;
const arcgisServerHost: string = 'https://vnptbinhduong.vn:6444';
export const environment = {
  production: true,
  PROVINCE_CODE: '74',
  PROVINCE_NAME: 'Bình Dương',
  INFORMATION: {
    INFO_NAME: 'Sở công thương tỉnh ...',
    INFO_ADDRESS: 'Trung tâm Hành chính tỉnh ...',
    INFO_EMAIL: 'soct@dev.gov.vn',
    INFO_PHONE: '(+84) 012 3456789',
    INFO_LOGO: '/assets/images/logo/logo-mini.png',
    INFO_LOGO_TRANSPARENT: '/assets/images/logo/logo.png',
  },
  HOST_NAME_API: hostNameAPI,
  HOST_NAME_ORIGIN: hostNameOrigin,
  BASE_HREF: '/dmit',
  SYSTEM_API_URL: hostNameAPI + '/doit-api-system/v1/',
  MAIN_API_URL: hostNameAPI + '/doit-api-main/v1/',
  CAT_API_URL: hostNameAPI + '/doit-api-cat/v1/',
  ITD_API_URL: hostNameAPI + '/doit-api-itd/v1/',
  ITP_API_URL: hostNameAPI + '/doit-api-itp/v1/',
  PB_API_URL: hostNameAPI + '/doit-api-pb/v1/',
  DYNAMIC_API_URL: hostNameAPI + '/doit-api-dynamic/v1/',
  SURVEY_API_URL: hostNameAPI + '/doit-api-fieldsurveytool/v1/',
  NOTIFICATION_API_URL: hostNameAPI + '/doit-api-notification/v1/',
  API_LGSP: 'https://api.binhduong.gov.vn:8687/',
  REPORT_API_URL: hostNameAPI + '/doit-api-report/v1/',
  UPLOAD_FILE_API_URL: hostNameAPI + '/doit-api-system/v1/File/UploadNew/',
  DOWNLOAD_FILE_API_URL: hostNameAPI + '/doit-api-system/v1/File/DownloadNew/',
  ARCGIS_SERVER_URL: arcgisServerHost + '/arcgis/rest/services',
  ARCGIS_SXD_URL: 'http://gisxd.binhduong.gov.vn:81',
  MQTT: {
    SERVER: '113.161.160.168',
    PROTOCOL: 'ws',
    PORT: 8083,
  },
  TRANSLATE_JSON_TEXT: '',
  VERSION: 'v1.0.26-prod',
  BROWSER_INFO:
    'Vui lòng sử dụng trình duyệt Google Chrome hoặc Microsoft Edge để có trải nghiệm tốt nhất',
  PROJCS: {
    NAME: 'BINHDUONG_VN2000',
    CENTRAL_MERIDIAN: 105.75,
  },
  DOWNLOAD_APP: {
    ANDROID_URL: '',
    IOS_URL: '',
  },
  BACKUP_NAME: 'DOIT_BD',
  GEOSERVER_WORK_SPACE_NAME: 'DOIT_DEMO',
  GEOSERVER_URL: hostNameOrigin + '/geoserver/',
  GEOSERVER_WMTS_CAPABILITIES:
    hostNameOrigin +
    '/geoserver/' +
    '/gwc/service/wmts?service=WMTS&version=1.1.1&request=GetCapabilities',
  MAPPROXY_URL: hostNameOrigin + '/mapproxy/',
  MAPPROXY_WMTS_CAPABILITIES:
    hostNameOrigin + '/mapproxy/' + '/wmts/1.0.0/WMTSCapabilities.xml',
  ARCGIS_WMTS_CAPABILITIES: '',
  SRID: 'EPSG:8439',
  CENTER_COORDINATES: '106.7$11.15',
  DEFAULT_ZOOM: 10,
  MIN_ZOOM: 10,
  MAX_ZOOM: 19,
  MIN_RESOLUTION: 25,
  MAX_RESOLUTION: 25,
  CUSTOM_GRID: 'custom_grid',
  DISTRICT_LAYER: 'PC_Districts',
  WARD_LAYER: 'DOIT_WARD',
  ENVIRONMENT_TYPE: 'demo',
  WKT: `PROJCS["EPSG:8439"$ 
      GEOGCS["EPSG:8439"$ 
        DATUM["Vietnam 2000"$ 
          SPHEROID["WGS 84"$ 6378137.0$ 298.257223563$ AUTHORITY["EPSG"$"7030"]]$ 
          TOWGS84[-191.90441429$ -39.30318279$ -111.45032835$ 0.00928836$ 0.01975479$ -0.00427372$ 0.252906278]$ 
          AUTHORITY["EPSG"$"6756"]
        ]$ 
        PRIMEM["Greenwich"$ 0.0$ AUTHORITY["EPSG"$"8901"]]$ 
        UNIT["degree"$ 0.017453292519943295]$ 
        AXIS["Geodetic longitude"$ EAST]$ 
        AXIS["Geodetic latitude"$ NORTH]$ 
        AUTHORITY["EPSG"$"4756"]
      ]$ 
      PROJECTION["Transverse_Mercator"]$ 
      PARAMETER["central_meridian"$ 105.75]$ 
      PARAMETER["latitude_of_origin"$ 0.0]$ 
      PARAMETER["scale_factor"$ 0.9999]$ 
      PARAMETER["false_easting"$ 500000.0]$ 
      PARAMETER["false_northing"$ 0.0]$ 
      UNIT["m"$ 1.0]$ 
      AXIS["x"$ EAST]$ 
      AXIS["y"$ NORTH]$ 
      AUTHORITY["EPSG"$"8439"]
    ]`,
};
