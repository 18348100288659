export const ConstantReport = {
  STATISTIC_GET_CREATEREPORT: "statistic",
  STATISTIC_GET_INSPECTIONREPORT: "statisticInspection",
  STATISTIC_GET_CREATEREPORTPBCERT: "StatisticPBCert",
  STATISTIC_GET_CREATEREPORTPBDOC: "StatisticPBDoc",

  STATISTIC_GET_DOCPROCSTEP: "PBDocProcStep",

  STATISTIC_GET_CREATEEXCEL: "statistic/exportExcel",
  STATISTIC_GET_CREATEEXCEL_PB: "{type}/exportExcel",
  STATISTIC_GET_CREATEPDF_PB: "{type}/exportPdf",

  STATISTIC_GET_RESULT_DOC : "ObjType/byObjClass/PBAppraisalResultOfDoc",

  STATISTIC_GET_CRITERION: 'ObjField/byCode?classCode={code}&classSubCode={subCode}',
  STATISTIC_GET_REPORT: "savereport",
  STATISTIC_DELETE_REPORT: "savereport/{id}",

  STATISTIC_GET_LIST_SAMPLE: "ManagementFileReport",
  STATISTIC_GET_SAMPLE_ID: "ManagementFileReport/download?path={path}",
  STATISTIC_SAMPLE_REPORT: "SampleReport",

  GET_LIST_WARD_OF_DISTRICT: 'ward/of/{districtCode}?sortBy={sortBy}&sortOrder={sortOrder}&&searchKey={searchKey}',

  SAMPLE_REPORT_GET_DETAIL: 'managementFileReport/{id}',
  SAMPLE_REPORT_VIEW_FILE: 'managementFileReport/download?path={path}',

  CERTIFICATE_GET_LIST: 'manageCertificate',
  CERTIFICATE_GET_FILE_TO_VIEW: 'manageCertificate/load/{code}/{id}',

  CERTIFICATE_VIEW_FILE: 'manageCertificate/download?path={path}',
  CERTIFICATE_DELETE_FILE_AFTER_VIEW: 'manageCertificate/delete/file?path={path}',

  REPORT_SAMPLE_VIEW_FILE: 'ManagementFileReport/download?path={path}',
  REPORT_DELETE_SAMPLE_VIEW_FILE: 'ManagementFileReport/file?path={path}',
  GET_LIST_INDUSTRY_CLUSTER:'IndustrialCluster',

  ESR_LIST_PAGING_REPORT_TEMPLATE : 'ESRReportTemplate/listPaging?pageIndex={page}&pageSize={pageSize}&sortOrder={sort}&sortBy={sortBy}&searchKey={searchKey}',
  ESR_CREATE_REPORT_TEMPLATE: 'ESRReportTemplate',
  ESR_CRUD_REPORT_TEMPLATE: 'ESRReportTemplate/{id}',
  ESR_CRUD_BESE_ENT_BUSINESSOCDE : 'BaseEnt/byBusinessCode/{code}',
  ESR_CRUD_ESRReportAssignment : 'ESRReportAssignment/Ent/listPaging?pageIndex={page}&pageSize={pageSize}&sortOrder={sort}&sortBy={sortBy}&searchKey={searchKey}',
  ESR_IMPORT_EXCEL_REPORT_ASSIGNMENT: 'ESRReportAssignment/importExcel',
  ESR_CRUD_REPORT_ASSIGNMENT: 'ESRReportAssignment/{id}',
  ESR_CREAD_MULTI_REPORT_ASSIGNMENT: 'ESRReportAssignment/save',
  ESR_LIST_AGGRE_BUSINESS_LIST: '/ESRReportData/businessReportedList?sortBy={sortBy}&sortOrder={sort}&searchKey={searchKey}&reportTemplateCode={reportTemplateCode}',
  ESR_LIST_AGGRE_REPORT_TOTAL: '/ESRReportData/SynthesiseReport?sortBy={sortBy}&sortOrder={sort}&searchKey={searchKey}&reportTemplateCode={reportTemplateCode}&businessCodes={businessCodes}',

  ESR_GET_REPORT_ASSIGNMENT: 'ESRReportAssignment?reportTemplateCode={reportTemplateCode}',
  ESR_LIST_AGGRE_REPORT: '/ESRReportData/businessReportGeneral?sortBy={sortBy}&sortOrder={sort}&searchKey={searchKey}',
  ESR_LIST_AGGRE_REPORT_DOWNLOAD: 'ESRReportData/exportDocument?sortBy={sortBy}&sortOrder={sort}&searchKey={searchKey}&reportTemplateCode={reportTemplateCode}&docType={docType}&businessCodes={businessCodes}',

  ESR_REPORT_FORM_GET_LIST_PAGING: 'ESRReportForm/listPaging?sortBy={sortBy}&sortOrder={sortOrder}&pageIndex={pageIndex}&pageSize={pageSize}&searchKey={searchKey}',
  ESR_REPORT_FORM_CRUD: 'ESRReportForm',
  ESR_REPORT_FORM_EXPORT_EXCEL: 'ESRReportForm/exportExcel?code={code}',

  ESR_REPORT_FORM_VARIABLE_GET_LIST_PAGING: 'ESRReportVariable/listPaging?sortBy={sortBy}&sortOrder={sortOrder}&pageIndex={pageIndex}&pageSize={pageSize}&searchKey={searchKey}',
  ESR_REPORT_FORM_VARIABLE_CRUD: 'ESRReportVariable',
  ESR_REPORT_FORM_VARIABLE_PREVIEW: 'ESRReportVariable/CalculateMathFormula/{id}',
}
