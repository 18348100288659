import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { Observable, TimeoutError, throwError } from 'rxjs';
import { InjectionToken, Injectable, Inject } from '@angular/core';
import {
  HttpHandler,
  HttpRequest,
  HttpInterceptor,
  HttpEvent,
} from '@angular/common/http';
import { catchError, timeout } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';

const DEFAULT_TIMEOUT = new InjectionToken<number>('defaultTimeout');
const defaultTimeout = 300000;

@Injectable()
export class TimeoutInterceptor implements HttpInterceptor {
  constructor(
    @Inject(DEFAULT_TIMEOUT) protected defaultTimeout,
    private _toastrService: ToastrService
  ) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const timeoutNumber =
      Number(req.headers.get('timeout')) || this.defaultTimeout;
    return next.handle(req).pipe(
      timeout(timeoutNumber),
      catchError((error) => {
        if (error instanceof TimeoutError) {
          this._toastrService.error(
            `Hệ thống không phản hồi sau ${
              Math.round(timeoutNumber / 1000 / 60) ?? 0
            } phút`
          );
          return throwError(error);
        }
      })
    );
  }
}

/**
 * Provider POJO for the interceptor
 */
export const TimeoutInterceptorProvider = {
  provide: HTTP_INTERCEPTORS,
  useClass: TimeoutInterceptor,
  multi: true,
};
export const DefaulTimeoutProvider = {
  provide: DEFAULT_TIMEOUT,
  useValue: defaultTimeout,
};
