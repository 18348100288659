import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { SystemRoutingEnum } from '@shared/enums/routing.enum';
import { APP_BASE_HREF } from '@angular/common';
import { environment } from '@env/environment';
import { AuthGuard } from './core/guard/auth.guard';

const routes: Routes = [
  { path: '', redirectTo: SystemRoutingEnum.AUTH, pathMatch: 'full' },
  // {
  //   path: SystemRoutingEnum.LANDING_PAGE,
  //   loadChildren: () =>
  //     import('./pages/landing-page/landing-page.module').then(
  //       (m) => m.LandingPageModule
  //     ),
  // },
  // {
  //   path: SystemRoutingEnum.MAP_IOC,
  //   loadChildren: () =>
  //     import('./pages/maps-ioc/data-management-ol/data-management.module').then(
  //       (m) => m.DataManagementModule
  //     ),
  // },
  {
    path: SystemRoutingEnum.AUTH,
    loadChildren: () =>
      import('./pages/auth/auth.module').then((m) => m.AuthModule),
  },
  {
    path: '',
    canLoad: [AuthGuard],
    loadChildren: () =>
      import('./pages/pages.module').then((m) => m.PagesModule),
  },
  { path: '**', redirectTo: SystemRoutingEnum.ERROR },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  providers: [{ provide: APP_BASE_HREF, useValue: environment.BASE_HREF }],
  exports: [RouterModule],
})
export class AppRoutingModule {}
