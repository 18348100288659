import { Injectable } from '@angular/core';
import {
  CanActivate,
  CanLoad,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  CanActivateChild,
} from '@angular/router';
import { AuthService } from '@core/services/auth/auth.service';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { CacheAll } from '../lib/cache';
import { CacheConst } from '../constant/AnyConstant';
import { SystemRoutingEnum } from '@shared/enums/routing.enum';
import { MenuPermissionGuard } from './menu-permission.guard';
import { MainService } from '../services/main/main.service';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard
  extends MenuPermissionGuard
  implements CanActivate, CanLoad, CanActivateChild
{
  constructor(
    private _toastrService: ToastrService,
    public authService: AuthService,
    public routerService: Router,
    public mainService: MainService
  ) {
    super(authService, mainService, routerService);
  }

  async canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Promise<boolean> {
    const isLogin = this.authService.isLoginUser();
    if (!isLogin) return this.isAuth();
    return await super.canActivate(route, state);
  }

  canLoad(): boolean {
    const isLogin = this.authService.isLoginUser();
    if (!isLogin) {
      const userSession = CacheAll.getCookie(CacheConst.USER_SESSION);
      if (userSession) CacheAll.deleteCookie(CacheConst.USER_SESSION);
      this.routerService.navigate([SystemRoutingEnum.AUTH], {});
    }
    return isLogin;
  }

  async canActivateChild(
    childRoute: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Promise<boolean> {
    const isLogin = this.authService.isLoginUser();
    if (!isLogin) return this.isAuth();
    return await super.canActivateChild(childRoute, state);
  }

  isAuth() {
    const isLogin = this.authService.isLoginUser();
    if (!isLogin) {
      const userSession = CacheAll.getCookie(CacheConst.USER_SESSION);
      if (userSession) {
        CacheAll.deleteCookie(CacheConst.USER_SESSION);
        this._toastrService.warning(
          'Đã hết phiên đăng nhập. Vui lòng đăng nhập để sử dụng phần mềm!'
        );
      } else {
        this._toastrService.warning('Vui lòng đăng nhập để sử dụng phần mềm!');
      }
      this.routerService.navigate([SystemRoutingEnum.AUTH], {});
    }
    return isLogin;
  }
}
