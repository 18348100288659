export const LayerConstant = {
    LAYER_GET_LIST_DATA: '{layer_code}/{layer_subCode}?searchKey={searchKey}',
    LAYER_GET_LIST_DATA_COMBOBOX:'{layer_code}/{layer_subCode}?sortBy={sortBy}',
    LAYER_GET_LIST_DATA_BY_CODE: '{layer_code}/{layer_subCode}?code={code}',
    LAYER_GET_LIST_DATA_BY_GROUP_CODE: '{layer_code}/{layer_subCode}/groupByCode?code={code}&searchKey={searchKey}',
    LAYER_GET_LIST_PAGING_DATA: '{layer_code}/{layer_subCode}/listPaging?pageIndex={page}&sortOrder={sort}&sortBy={sortBy}&searchKey={searchKey}&es=0',
    LAYER_GET_LIST_PAGING_DATA_FROM_TO: '{layer_code}/{layer_subCode}/listPaging?pageIndex={page}&sortOrder={sort}&sortBy={sortBy}&fromDate={fromDate}&toDate={toDate}&searchKey={searchKey}&es=0',
    LAYER_GET_LIST_PAGING_DATA_BY_CODE: '{layer_code}/{layer_subCode}/listPaging?code={code}&pageIndex={page}&sortOrder={sort}&sortBy={sortBy}&searchKey={searchKey}&es=0',
    LAYER_DETAIL_DATA: '{layer_code}/{layer_subCode}/{data_id}',
    LAYER_DETAIL_DATA_BY_CODE: '{layer_code}/{layer_subCode}/byCode/{code}',
    LAYER_CREATE_DATA: '{layer_code}/{layer_subCode}',
    LAYER_UPDATE_DATA: '{layer_code}/{layer_subCode}/{data_id}',
    LAYER_DELETE_DATA: '{layer_code}/{layer_subCode}/{data_id}',
    LAYER_IMPORT_DATA: '{layer_code}/{layer_subCode}/importExcel',
    LAYER_EXPORT_DATA: '{layer_code}/{layer_subCode}/exportExcel?searchKey={searchKey}',
    LAYER_EXPORT_DATA_CHEMISTRY: '{layer_code}/{layer_subCode}/exportExcel/all?searchKey={searchKey}',

    LINK_TABLE_GET_LIST_BY_CODE: '{table_code}/byCode/{code}?searchKey={searchKey}',
    LINK_TABLE_GET_LIST_SORT_BY_CODE: '{table_code}/byCode/{code}?searchKey={searchKey}&sortBy={sortBy}',
    LINK_TABLE_CHILDS_GET_LIST_BY_CODE: '{table_code}/byCode/{code}/{childCode}?searchKey={searchKey}',
    ENTERPRISE_DPI_GET_DETAIL_BY_CODE: 'EnterprisesDPI/byCode/{code}',
    ENTERPRISE_DPI_GET_DETAIL_BY_ID: 'EnterprisesDPI/{id}',
    ENTERPRISE_DPI_AUTO_GROUPING: 'EnterprisesDPI/Grouping',
    ENTERPRISE_DPI_LIST_PAGING: 'EnterprisesDPI/listPaging?pageIndex={page}&sortOrder={sort}&sortBy={sortBy}&searchKey={searchKey}&minCharterCapital={minCharterCapital}&maxCharterCapital={maxCharterCapital}&typeCode={typeCode}&nationalityCode={nationalityCode}&mainSectorCode={mainSectorCode}&supportIndustryId={supportIndustryId}&group1={group1}&group2={group2}',
    ENTERPRISE_DPI_EXPORT_EXCEL: 'EnterprisesDPI/exportExcel?searchKey={searchKey}&minCharterCapital={minCharterCapital}&maxCharterCapital={maxCharterCapital}&typeCode={typeCode}&nationalityCode={nationalityCode}&mainSectorCode={mainSectorCode}&supportIndustryId={supportIndustryId}&group1={group1}&group2={group2}',

    FIELDS_GET_DROPDOWN: '{code}/{parent_id}',

    ENTERPRISE_POST_GET_LIST_BY_CODE_STATUSCODE_PAGING: '{extraCode}/{extraSubCode}/listPaging?pageIndex={page}&sortOrder={sort}&sortBy={sortBy}&searchKey={searchKey}&statusCode={statusCode}',
    ENTERPRISE_POST_GET_LIST_BY_CODE_CENSORSHIPSTATUSCODE_PAGING: '{extraCode}/{extraSubCode}/listPaging?pageIndex={page}&sortOrder={sort}&sortBy={sortBy}&searchKey={searchKey}&censorshipStatusCode={censorshipStatusCode}',
    ENTERPRISE_POST_GET_LIST_BY_ID: '{extraCode}/{extraSubCode}/{id}',
    ENTERPRISE_POST_UPDATE_BY_ID: '{extraCode}/{extraSubCode}/{id}',
    ENTERPRISE_POST_UPDATE_LIST_STATUS: '{extraCode}/{extraSubCode}',

    LAYER_GET_ALL_FIELDS: 'objField',
    LAYER_GET_FIELDS: 'objField?objClassCode={layer_code}&objClassSubCode={layer_subCode}&sortOrder=asc&sortBy=seqNum',
    LAYER_GET_FIELDS_WITH_SEARCH: 'objField?objClassCode={layer_code}&objClassSubCode={layer_subCode}&searchKey={searchKey}&sortOrder=asc&sortBy=seqNum',
    LAYER_GET_FIELDS_BY_DATATYPE: 'objField?objClassCode={layer_code}&objClassSubCode={layer_subCode}&searchKey={searchKey}&sortOrder=asc&sortBy=seqNum&dataType={dataType}',
    LAYER_GET_FIELDS_DETAILS: 'objField/{field_id}',
    LAYER_UPDATE_FIELDS: 'objField/{field_id}',
    // LAYER_GET_FIELDS_WITH_SEARCH_V2: 'objFieldV2?objClassCode={layer_code}&objClassSubCode={layer_subCode}&searchKey={searchKey}&sortOrder=asc&sortBy=seqNum',
    // LAYER_UPDATE_FIELDS_V2: 'objFieldV2/{field_id}',
    // LAYER_DELETE_FIELDS_V2: 'objFieldV2/{field_id}',
    // LAYER_CREATE_FIELDS_V2: 'ObjFieldV2',
    LAYER_GET_FIELDS_WITH_SEARCH_V2: 'dynObjField?objClassCode={layer_code}&objClassSubCode={layer_subCode}&searchKey={searchKey}&sortOrder=asc&sortBy=seqNum',
    LAYER_UPDATE_FIELDS_V2: 'dynObjField/{field_id}?objClassCode={objClassCode}',
    LAYER_DELETE_FIELDS_V2: 'dynObjField/{field_id}?objClassCode={objClassCode}',
    LAYER_CREATE_FIELDS_V2: 'dynObjField?objClassCode={objClassCode}',
    LAYER_CREATE_TABLE_V2: 'dynObjField',

    LAYER_LIST_GET_ALL: 'objClass?searchKey={searchKey}&funcModuleCodes={funcCode}&sortOrder=asc&sortBy=name',
    LAYER_LIST_GET_LIST: 'objClass?searchKey={searchKey}&funcModuleCodes={funcCode}&sortOrder={sort}&sortBy={sortBy}&tableStatus={tableStatus}&type={type}',
    LAYER_LIST_GET_LIST_PAGING: 'objClass/listpaging?searchKey={searchKey}&funcModuleCodes={funcCode}&sortOrder={sort}&sortBy={sortBy}&pageIndex={pageIndex}&pageSize={pageSize}&objClassGroupId={objClassGroupId}',
    // LAYER_LIST_GET_LIST_V2: 'ObjClassV2?searchKey={searchKey}&funcModuleCodes={funcCode}&sortOrder={sort}&sortBy={sortBy}&tableStatus={status}',
    LAYER_LIST_GET_LIST_V2: 'DynObjClass?searchKey={searchKey}&funcModuleCodes={funcCode}&sortOrder={sort}&sortBy={sortBy}&tableStatus={status}',
    LAYER_LIST_GET_LIST_BY_CODE: 'objClass?code={code}&subCode={subCode}',
    LAYER_LIST_GET_DETAIL: 'objClass/{layer_id}',
    LAYER_LIST_UPDATE: 'objClass/{layer_id}',
    LAYER_LIST_EXPORT_EXCEL: 'objClass/exportExcel',
    LAYER_LIST_BY_GROUP: 'objClass/layerGroup?searchKey={searchKey}&funcModuleCodes={funcCode}&sortOrder=asc&sortBy=Code',
    LAYER_CONFIG_LIST_BY_GROUP: 'objClass/layerGroup?searchKey={searchKey}&funcModuleCodes={funcCode}&sortOrder=asc&sortBy=Code&show=1',

    GROUP_LAYER_CRUD: 'ObjType/LayerGroup/{id}',
    GROUP_LAYER_ADD_LAYER: 'objClass/layerGroup/{id}',

    LAYER_ONCLICK_GET_DATA: "indTradeData/search",
    LAYER_SEARCH_SPATIAL: "indTradeData/getByIds",
    LAYER_SEARCH_SPATIAL_EXPORT_DATA: "indTradeData/exportExcelByIds",
    LAYER_ONCLICK_GET_DATA_ZONING: "indTradePlan/search",
    LAYER_SEARCH_SPATIAL_ZONING: "indTradePlan/getByIds",
    LAYER_SEARCH_SPATIAL_EXPORT_DATA_ZONING: "indTradePlan/exportExcelByIds",
    LAYER_SEARCH_SPATIAL_ZONING_GET_RESULT: "indTradePlan/getResult",
    LAYER_SEARCH_SPATIAL_ZONING_GET_CURRENT_DATA: "indTradePlan/getCurrentData",

    BASE_ENT_GET_LIST: "baseEnt?objClassCode={layer_code}&objClassSubCode={layer_subCode}&sortOrder=asc&sortBy=name",
    BASE_ENT_GET_SELECTBOX: "baseEnt/selectbox",
    BASE_ENT_GET_LIST_PAGING: "baseEnt/listPaging?pageIndex={page}&sortOrder={sort}&sortBy={sortBy}&searchKey={searchKey}",
    BASE_ENT_GET_DETAIL: "baseEnt/{id}",
    //TODO:git-sync
    BASE_ENT_GET_DETAIL_BY_CODE: "baseEnt/byCode/{code}",
    // BASE_ENT_GET_DETAIL_BY_CODE: "baseEnt/byCode/{code}?objClassCode={layerCode}&objClassSubCode={layerSubcode}",
    BASE_ENT_GET_DETAIL_BY_BUSINESS_CODE: "baseEnt/byBusinessCode/{businessCode}",
    BASE_ENT_EXPORT_EXCEL: "baseEnt/exportExcel",

    OBJ_TYPE_GET_LIST: "ObjType",
    OBJ_TYPE_GET_LIST_PAGING: "ObjType/listPaging?pageIndex={page}&sortOrder={sort}&sortBy={sortBy}&searchKey={searchKey}",
    OBJ_TYPE_GET_LIST_BY_OBJ_CLASSS: "ObjType/byObjClass/{objClass}?searchKey={searchKey}",
    GET_LIST_SAMPLE_CERTIFICATE:"ManageCertSample",
    IMPORT_CERT_SAMPLE: "ManageCertSample/import",
    
    MENU_CRUD: "menu",
    MENU_ACTIVE: "menu/hide",
    MENU_LIST_TREE: "menu/listtree",

    MENU_PERMISSION_CRUD: "menupermission",
    MENU_PERMISSION_DELETE_CASCADE: "menupermission/{id}/cascade",
    MENU_PERMISSION_PERMISSIONGROUP: "menupermission/permissiongroup/{id}?sortOrder={sortOrder}&sortBy={sortBy}",
    MENU_PERMISSION_BY_TOKEN: "menupermission/ofmine/menu?sortBy={sortBy}&sortOrder={sortOrder}",
    MENU_PERMISSION_ACCEPT: "menupermission/accept?path={path}",

    GROUP_OBJ_CLASS_V2_GET_LIST_PAGING: "objclassgroup/listpaging?sortBy={sortBy}&sortOrder={sortOrder}&pageIndex={pageIndex}&pageSize={pageSize}&searchKey={searchKey}",
    GROUP_OBJ_CLASS_V2_GET_LIST: "objclassgroup?sortBy={sortBy}&sortOrder={sortOrder}",
    GROUP_OBJ_CLASS_V2_CRUD: "objclassgroup",

    // OBJ_CLASS_V2_GET_LIST_PAGING: "objclassv2/listpaging?objClassGroupId={objClassGroupId}&sortBy={sortBy}&sortOrder={sortOrder}&pageIndex={pageIndex}&pageSize={pageSize}&searchKey={searchKey}",
    // OBJ_CLASS_V2_GET_LIST_PAGING_BY_PARENT_CODE: "objclassv2/listpaging?parentObjClassCode={parentObjClassCode}&sortBy={sortBy}&sortOrder={sortOrder}&pageIndex={pageIndex}&pageSize={pageSize}&searchKey={searchKey}",
    // OBJ_CLASS_V2_GET_LIST_OTHER_PAGING: "objclassv2/listOthersPaging?sortBy={sortBy}&sortOrder={sortOrder}&pageIndex={pageIndex}&pageSize={pageSize}&searchKey={searchKey}",
    // OBJ_CLASS_V2_GET_LIST_BY_PARENT_CODE: "objclassv2?sortBy={sortBy}&sortOrder={sortOrder}&parentObjClassCode={parentObjClassCode}&searchKey={searchKey}",
    // OBJ_CLASS_V2_GET_LIST: "objclassv2?sortBy={sortBy}&sortOrder={sortOrder}&objClassGroupId={objClassGroupId}&funcModuleCodes={funcModuleCodes}&searchKey={searchKey}&tableStatus={tableStatus}",
    // OBJ_CLASS_V2_GET_LIST_BY_OTHER_GROUP: "objclassv2?sortBy={sortBy}&sortOrder={sortOrder}&other=true&funcModuleCodes={funcModuleCodes}&searchKey={searchKey}&tableStatus={tableStatus}",
    // OBJ_CLASS_V2_CRUD: "objclassv2",
    OBJ_CLASS_V2_GET_LIST_PAGING: "DynObjClass/listpaging?objClassGroupId={objClassGroupId}&sortBy={sortBy}&sortOrder={sortOrder}&pageIndex={pageIndex}&pageSize={pageSize}&searchKey={searchKey}&funcModuleCodes={funcModuleCodes}",
    OBJ_CLASS_V2_GET_LIST_PAGING_BY_PARENT_CODE: "DynObjClass/listpaging?parentObjClassCode={parentObjClassCode}&sortBy={sortBy}&sortOrder={sortOrder}&pageIndex={pageIndex}&pageSize={pageSize}&searchKey={searchKey}",
    OBJ_CLASS_V2_GET_LIST_OTHER_PAGING: "DynObjClass/listPaging?other=true&sortBy={sortBy}&sortOrder={sortOrder}&pageIndex={pageIndex}&pageSize={pageSize}&searchKey={searchKey}&funcModuleCodes={funcModuleCodes}",
    OBJ_CLASS_V2_GET_LIST_BY_PARENT_CODE: "DynObjClass?sortBy={sortBy}&sortOrder={sortOrder}&parentObjClassCode={parentObjClassCode}&searchKey={searchKey}",
    OBJ_CLASS_V2_GET_LIST_BY_FUNC_MODULE_CODE: "DynObjClass?sortBy={sortBy}&sortOrder={sortOrder}&parentObjClassCode={parentObjClassCode}&searchKey={searchKey}&funcModuleCodes={funcModuleCodes}",
    OBJ_CLASS_V2_GET_LIST: "DynObjClass?sortBy={sortBy}&sortOrder={sortOrder}&objClassGroupId={objClassGroupId}&funcModuleCodes={funcModuleCodes}&searchKey={searchKey}&tableStatus={tableStatus}",
    OBJ_CLASS_V2_GET_LIST_BY_OTHER_GROUP: "DynObjClass?sortBy={sortBy}&sortOrder={sortOrder}&other=true&funcModuleCodes={funcModuleCodes}&searchKey={searchKey}&tableStatus={tableStatus}",
    OBJ_CLASS_V2_CRUD: "DynObjClass",
    OBJ_CLASS_V2_STANDARDIZE: "DynObjClass/Standardize",
    OBJ_CLASS_V2_CLONE: "DynObjClass/Clone",

    // OBJ_FIELD_V2_GET_LIST_PAGING: "objfieldv2/listpaging?sortBy={sortBy}&sortOrder={sortOrder}&searchKey={searchKey}&pageIndex={pageIndex}&pageSize={pageSize}&objClassCode={objClassCode}&objClassSubCode={objClassSubCode}",
    // OBJ_FIELD_V2_GET_LIST_PAGING_BY_CODE: "objfieldv2/byCode?sortBy={sortBy}&sortOrder={sortOrder}&searchKey={searchKey}&pageIndex={pageIndex}&pageSize={pageSize}&objClassCode={objClassCode}&objClassSubCode={objClassSubCode}&level={level}",
    // OBJ_FIELD_V2_GET_LIST: "objfieldv2?sortBy={sortBy}&sortOrder={sortOrder}&objClassCode={objClassCode}&objClassSubCode={objClassSubCode}",
    // OBJ_FIELD_V2_CRUD: "objfieldv2",
    OBJ_FIELD_V2_GET_LIST_PAGING: "dynObjField/listpaging?sortBy={sortBy}&sortOrder={sortOrder}&searchKey={searchKey}&pageIndex={pageIndex}&pageSize={pageSize}&objClassCode={objClassCode}&objClassSubCode={objClassSubCode}",
    OBJ_FIELD_V2_GET_LIST_PAGING_BY_CODE: "dynObjField/byCode?sortBy={sortBy}&sortOrder={sortOrder}&searchKey={searchKey}&pageIndex={pageIndex}&pageSize={pageSize}&objClassCode={objClassCode}&objClassSubCode={objClassSubCode}&level={level}",
    OBJ_FIELD_V2_GET_LIST: "dynObjField?sortBy={sortBy}&sortOrder={sortOrder}&objClassCode={objClassCode}&objClassSubCode={objClassSubCode}",
    OBJ_FIELD_V2_CRUD: "dynObjField",

    OBJ_TYPE_V2_CRUD: "objtype",

    LEVEL_CERTIFICATION_GET_LIST_PAGING: "LevelCertification/listpaging?sortBy={sortBy}&sortOrder={sortOrder}&searchKey={searchKey}&pageIndex={pageIndex}&pageSize={pageSize}",
    LEVEL_CERTIFICATION_GET_LIST: "LevelCertification?sortBy={sortBy}&sortOrder={sortOrder}",
    LEVEL_CERTIFICATION_CRUD: "LevelCertification/{id}",

    INDUSTRIAL_CLUSTER_ENT_GET_LIST_PAGING: "IndustrialClusterEnt/listpaging?pageIndex={pageIndex}&pageSize={pageSize}&sortBy={sortBy}&sortOrder={sortOrder}&searchKey={searchKey}",
    INDUSTRIAL_CLUSTER_ENT_GET_LIST: "IndustrialClusterEnt?sortBy={sortBy}&sortOrder={sortOrder}&searchKey={searchKey}",
    INDUSTRIAL_CLUSTER_ENT_CRUD: "IndustrialClusterEnt/{id}",
    INDUSTRIAL_CLUSTER_ENT_GET_LIST_BY_CODE: 'IndustrialClusterEnt/byCode/{code}?searchKey={searchKey}',
}
