import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { LoaderService } from '@app/core/services/loader/loader.service';
import { environment } from '@env/environment';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class LoaderComponent implements OnInit {
  private _isLoading: Subject<boolean> = this._loaderService.isLoading;

  readonly INFORMATION = environment.INFORMATION;

  title: string = '';
  base64String: string = '';

  constructor(
    private _loaderService: LoaderService,
    private _spinnerService: NgxSpinnerService
  ) {}

  ngOnInit(): void {
    this._compressImage(this.INFORMATION.INFO_LOGO_TRANSPARENT, 128, 128).then(
      (compressed) => {
        this.base64String = compressed as string;
      }
    );
    this._isLoading.subscribe((response) => {
      if (response) {
        this._spinnerService.show();
      } else {
        this._spinnerService.hide();
      }
    });
  }

  private _compressImage(src, newX, newY) {
    return new Promise((res, rej) => {
      const img = new Image();
      img.src = src;
      img.onload = () => {
        const elem = document.createElement('canvas');
        elem.width = newX;
        elem.height = newY;
        const ctx = elem.getContext('2d');
        ctx.drawImage(img, 0, 0, newX, newY);
        const data = ctx.canvas.toDataURL();
        res(data);
      };
      img.onerror = (error) => rej(error);
    });
  }

  renderSpinner() {
    const html = this.base64String
      ? `
    <div class="all-page-spinner-image">
      <img src="${this.base64String}"/>
    </div>`
      : `
    <div class="all-page-spinner-image">
      <em class="mdi mdi-spin mdi-36px mdi-clock" style="color: #93bfec;position: absolute;top: 50%;left: 50%;transform: translate(-50%, -50%);"></em>
    </div>
    `;

    return html;
  }
}
