import { Injectable, Type } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  CanActivateChild,
  CanLoad,
  RouterStateSnapshot,
} from '@angular/router';
import { Router } from '@angular/router';
import { SystemRoutingEnum } from '@shared/enums/routing.enum';
import { MainService } from '../services/main/main.service';
import { ApiReturnModel } from '@app/shared/models/global.model';
import { AuthService } from '../services/auth/auth.service';
import { PagesComponent } from '@app/pages/pages.component';

@Injectable({
  providedIn: 'root',
})
export class MenuPermissionGuard implements CanActivate, CanActivateChild {
  constructor(
    public authService: AuthService,
    public mainService: MainService,
    public routerService: Router
  ) {}

  async canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Promise<boolean> {
    return await this.hasPermission(route, state);
  }

  async canActivateChild(
    childRoute: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Promise<boolean> {
    return await this.hasPermission(childRoute, state);
  }

  async hasPermission(
    route?: ActivatedRouteSnapshot,
    state?: RouterStateSnapshot
  ) {
    const validUrl = this.getValidUrl(route, state);
    if (!validUrl) return false;

    if (
      validUrl.includes(`${SystemRoutingEnum.AUTH}`) ||
      validUrl.includes(`${SystemRoutingEnum.ERROR}`)
    )
      return true;

    const hasPermission = await this.checkMenuPermission(validUrl);
    if (!hasPermission) return this.navigateDefaultPage(validUrl);
    return hasPermission;
  }

  async checkMenuPermission(url: string) {
    const promise = await this.mainService.checkMenuPermission(url).toPromise();
    const response = promise as ApiReturnModel;
    return response.code === 0;
  }

  isOtherRole() {
    const isAdmin = this.authService.isAdmin();
    const isEnt = this.authService.isEnt();
    const result = !isAdmin && !isEnt;
    return result;
  }

  getValidUrl(
    route?: ActivatedRouteSnapshot,
    state?: RouterStateSnapshot
  ): string {
    const total = [];
    this.getFullPath(route, total);
    const startIndex = 2;
    const endIndex = 4;
    const listUrl = total.slice(startIndex, endIndex);
    const url =
      listUrl?.[0].indexOf('/') > -1
        ? listUrl[0]
        : listUrl?.filter(Boolean)?.join('/');
    return url;
  }

  navigateDefaultPage(url: string) {
    const isOtherRole = this.isOtherRole();
    const isMapPage = url.includes(`${SystemRoutingEnum.DATA_MANAGEMENT}/maps`);
    if (isOtherRole && isMapPage)
      this.routerService.navigate([`${SystemRoutingEnum.USER_PROFILE}`]);
    this.routerService.navigate([`${SystemRoutingEnum.ERROR}/401`]);
    return isOtherRole && isMapPage;
  }

  getFullPath(route?: ActivatedRouteSnapshot, total = []) {
    const path = route.routeConfig?.path;
    if (!route?.parent) return path;
    total.unshift(path);
    return this.getFullPath(route.parent, total);
  }
}
