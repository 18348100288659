import { BrowserModule } from '@angular/platform-browser';
import { LOCALE_ID, NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ToastrModule } from 'ngx-toastr';
import { IConfig, NgxMaskModule } from 'ngx-mask';

import { AppComponent } from './app.component';
import { FormsModule } from '@angular/forms';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpClient, HttpClientModule } from '@angular/common/http';

import { AuthService } from '@core/services/auth/auth.service';
import { ErrorInterceptorProvider } from './core/interceptor/handle-error.intercept';
import { HeaderInterceptorProvider } from './core/interceptor/header-config.intercept';
import { MenuStateService } from './core/services/state/menu-state.service';
import { GlobalVariables } from './core/constant/global-variables';
import '@angular/common/locales/global/vi';
import { DatePipe, DecimalPipe } from '@angular/common';
import { LoaderInterceptorProvider } from './core/interceptor/loader.interceptor';
import { SharedModule } from './shared/shared.module';
import { NgIdleModule } from '@ng-idle/core';
import {
  DefaulTimeoutProvider,
  TimeoutInterceptorProvider,
} from './core/interceptor/timeout-config.intercept';

const maskConfig: Partial<IConfig> = {
  allowNegativeNumbers: true,
  thousandSeparator: '.',
  decimalMarker: ',',
};

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    HttpClientModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    FormsModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: httpTranslateLoader,
        deps: [HttpClient],
      },
    }),
    ToastrModule.forRoot({
      positionClass: 'toast-bottom-right',
      preventDuplicates: true,
      maxOpened: 5,
      autoDismiss: true,
    }),
    NgxMaskModule.forRoot(maskConfig),
    SharedModule,
    NgIdleModule.forRoot(),
  ],
  providers: [
    AuthService,
    MenuStateService,
    GlobalVariables,
    HeaderInterceptorProvider,
    DefaulTimeoutProvider,
    TimeoutInterceptorProvider,
    ErrorInterceptorProvider,
    LoaderInterceptorProvider,
    { provide: LOCALE_ID, useValue: 'vi' },
    { provide: DecimalPipe },
    { provide: DatePipe },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}

// AOT compilation support
export function httpTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}
