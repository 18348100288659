export enum SystemRoutingEnum {
  LANDING_PAGE = "home",
  HOME = "data-management",
  ENT_ROLE_HOME = "business-report",
  AUTH = "auth",
  USER_PROFILE = "user",
  ERROR = "error",
  SYSTEM_MANAGEMENT = "system-management",
  DATA_MANAGEMENT = "data-management",
  ZONING_MANAGEMENT = "zoning-management",
  CATEGORY = "category",
  REPORT_FORM = "report-form",
  REPORT_FORM_MANAGEMENT = "report-form-management",
  STATISTIC = "statistic",
  DATA_SHARING = "data-sharing",
  SHARING = "sharing",
  BUSINESS_PROCESS = "major-handling",
  INDICATOR = "indicators",
  BUSINESS_REPORT = "business-report",
  DIGITIZED_RECORD = "digitized-records",
  DIGITIZED_RECORD_FOR_CLUSTER = "cluster",
  INSPECTION = "inspection",
  INSPECTION_V2 = "inspection-v2",
  FIELD_SURVEY = "field-survey",
  INTRODUCE = "introduce",
  DASHBOARD = "dashboard",
  MAP_IOC = 'ioc',
}
