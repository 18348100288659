export const Constant = {
    GET_INDUSTRIAL_CLUSTER: 'IndustrialCluster/{id}',
    GET_MALL: 'Mall/{id}',
    GET_MARKET: 'Market/{id}',
    OBJ_TYPE_OBJ_CLASS: 'ObjType/byObjClass/{objClass}?sortBy={sortBy}&sortOrder={sortOrder}',

    GET_RECORD_BY_LAYER: '{layer}{subCode}?sortBy={sortBy}&sortOrder={sortOrder}&searchKey={searchKey}&',
    GET_RECORD_BY_LAYER_LIST_PAGING: '{layer}{subCode}/listPaging?pageIndex={pageIndex}&pageSize={pageSize}&sortBy={sortBy}&sortOrder={sortOrder}&searchKey={searchKey}&',
    GET_RECORD_INSPECTION_BY_LAYER_LIST_PAGING: "InspectionPlanTarget/listPaging?pageIndex={pageIndex}&pageSize={pageSize}&sortBy={sortBy}&sortOrder={sortOrder}&searchKey={searchKey}&inspectionType={inspectionType}",
    GET_RECORD_INSPECTION_EXCEL: "InspectionPlanTarget/exportExcel?inspectionType={inspectionType}&year={year}&statisticType={statisticType}",

    GET_EXCELL_BY_LAYER: '{layer}{subCode}/exportExcel?sortBy={sortBy}&sortOrder={sortOrder}&searchKey={searchKey}&',
    GET_RECORD_BY_PBCERT:'PBCertificate?sortBy={sortBy}&sortOrder={sortOrder}&searchKey={searchKey}&',
    GET_RECORD_BY_PBCERT_LIST_PAGING:'PBCertificate/listPaging?pageIndex={pageIndex}&pageSize={pageSize}&sortBy={sortBy}&sortOrder={sortOrder}&searchKey={searchKey}&',
    GET_RECORD_BY_PBDOC:'PBDocument?sortBy={sortBy}&sortOrder={sortOrder}&searchKey={searchKey}&',
    GET_RECORD_BY_PBDOC_LIST_PAGING:'PBDocument/listPaging?pageIndex={pageIndex}&pageSize={pageSize}&sortBy={sortBy}&sortOrder={sortOrder}&searchKey={searchKey}&',
    EXPORT_RECORD_BY_PBDOC:'{type}/exportExcel',
    DOWNLOAD_CRITERIAL:'PBDocument/exportCriterial/{docmentId}',

    UPLOAD_LICENSE:'ManagementFileReport',
    DOWNLOAD_LICENSE:'ManagementFileReport/load/{code}/{id}',



    GET_ALL_INDUSTRIAL_CLUSTER: 'IndustrialCluster?sortBy={sortBy}&sortOrder={sortOrder}&searchKey={searchKey}',
    GET_ALL_MALL: 'Mall?sortBy={sortBy}&sortOrder={sortOrder}&searchKey={searchKey}',
    GET_ALL_MARKET: 'Market?sortBy={sortBy}&sortOrder={sortOrder}&searchKey={searchKey}',

    GET_LIST_DISTRICT: 'district/of/{provinceCode}?sortBy={sortBy}&sortOrder={sortOrder}&searchKey={searchKey}',
    GET_LIST_WARD: 'ward/of/{districtCode}?sortBy={sortBy}&sortOrder={sortOrder}&&searchKey={searchKey}',


    GET_PAGE_INDUSTRIAL_CLUSTER: 'IndustrialCluster/listPaging?pageIndex={pageIndex}&pageSize={pageSize}&sortBy={sortBy}&sortOrder={sortOrder}&searchKey={searchKey}',
    GET_PAGE_MALL: 'Mall/listPaging?pageIndex={pageIndex}&pageSize={pageSize}&sortBy={sortBy}&sortOrder={sortOrder}&searchKey={searchKey}',
    GET_PAGE_MARKET: 'Market/listPaging?pageIndex={pageIndex}&pageSize={pageSize}&sortBy={sortBy}&sortOrder={sortOrder}&searchKey={searchKey}',

    UPDATE_INDUSTRIAL_CLUSTER: 'IndustrialCluster/{id}',
    UPDATE_MALL: 'Mall/{id}',
    UPDATE_MARKET: 'Market/{id}',

    CREATE_INDUSTRIAL_CLUSTER: 'IndustrialCluster',
    CREATE_MALL: 'Mall',
    CREATE_MARKET: 'Market',

    DELETE_INDUSTRIAL_CLUSTER: 'IndustrialCluster/{id}',
    DELETE_MALL: 'Mall/{id}',
    DELETE_MARKET: 'Market/{id}',

    GET_ALL_INDUSTRIAL_PARK: 'IndustrialPark?sortBy={sortBy}&sortOrder={sortOrder}&searchKey={searchKey}',

    GET_ENT_INDUSTRIAL_CLUSTER: 'IndustrialClusterEnt/byCode/{code}'
}
