import { Injectable } from '@angular/core';
import { SidebarParent } from '@app/shared/models/sidebar.model';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class MenuStateService {

  execChange: Subject<SidebarParent[]> = new Subject<SidebarParent[]>();

  constructor() {}

  /**
   * Use to change user name 
   * @data type: string
   */
  menuChange(data: SidebarParent[]) {
      this.execChange.next(data);
  }
}
