import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Constant } from '@core/constant/api-system/Constant';
import {
  LoginModel,
  UserDataModel,
  ResetPassModel,
} from '@shared/models/auth.model';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { CacheAll } from '@core/lib/cache';
import { CacheConst } from '@app/core/constant/AnyConstant';
import { Base64 } from '@core/lib/encode';
import { ApiReturnModel } from '@shared/models/global.model';
import { sha256 } from 'js-sha256';
import { RoleType } from '@app/shared/enums/role-type.enum';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  systemApiUrl = environment.SYSTEM_API_URL;
  loginData: LoginModel;

  public get userId(): string {
    return CacheAll.getCookie(CacheConst.USER_ID);
  }
  public get deviceId(): string {
    return CacheAll.getCookie(CacheConst.DEVICE_ID);
  }
  public get userDetail(): any {
    return this.getCurrentUser();
  }
  public get token(): string {
    return CacheAll.getCookie(CacheConst.USER_TOKEN);
  }

  constructor(
    private _httpClient: HttpClient,
    private _routerService: Router,
    private _toastrService: ToastrService,
    private _translateService: TranslateService
  ) {}

  getAppDownloadURL() {
    const results = {
      androidURL: environment.DOWNLOAD_APP.ANDROID_URL,
      iosURL: environment.DOWNLOAD_APP.IOS_URL,
    };
    return results;
  }

  isLoginUser(): boolean {
    return !!this.token;
  }

  checkValidUser(callback) {
    let token = CacheAll.getCookie(CacheConst.USER_TOKEN);
    this.checkValidToken(token).subscribe(
      (rs) => {
        let result = rs as ApiReturnModel;
        if (result.code == 0) {
          callback(true);
        } else {
          callback(false);
        }
      },
      (error) => {
        callback(false);
      }
    );
  }

  checkInvalidResponse(data: ApiReturnModel): boolean {
    if (data?.code == 1) {
      CacheAll.deleteCookie(CacheConst.USER_TOKEN);
      CacheAll.deleteCookie(CacheConst.USER_ID);
      CacheAll.deleteCookie(CacheConst.USER_IMAGE);
      CacheAll.deleteCookie(CacheConst.USER_DETAIL);
      CacheAll.deleteCookie(CacheConst.USER_SESSION);
      CacheAll.deleteCookie(CacheConst.IS_FIRST);
      CacheAll.deleteCookie(CacheConst.IS_AGREE);
      CacheAll.deleteCookie(CacheConst.IS_AGREE_NEW);
      let currentUrl = window.location.pathname;
      this._routerService.navigate(['auth'], {
        queryParams: { redirectUrl: currentUrl },
      });
      if (this._translateService)
        this._toastrService.error(
          this._translateService.instant('auth_invalid-user')
        );
      else
        this._toastrService.error(
          'Người dùng không hợp lệ, vui lòng đăng nhập lại!'
        );
      return true;
    } else {
      return false;
    }
  }

  isAdmin(): boolean {
    const role = this.userDetail?.role?.code;
    const isAdmin = role === RoleType.Admin;
    return isAdmin;
  }

  isEnt(): boolean {
    const role = this.userDetail?.role?.code;
    const isEnt = role === RoleType.Enterprise;
    return isEnt;
  }

  getCurrentRole(): string {
    return this.userDetail?.role?.code;
  }

  getCurrentUser() {
    const userDetailCookie = CacheAll.getCookie(CacheConst.USER_DETAIL);
    if (!userDetailCookie) return;
    return JSON.parse(Base64.decode(userDetailCookie));
  }

  checkLoginType(str: string) {
    if (str.includes('@') && str.includes('.')) {
      return 'mail';
    } else if (!isNaN(parseFloat(str)) && this.checkPhoneNumber(str)) {
      return 'phone';
    } else {
      return 'username';
    }
  }

  checkPhoneNumber(value): boolean {
    let pattern = new RegExp(
      /0+(2\d\d|3[2-9]|5[6|8|9]|9\d(?!5)|8[1-9]|7[0|6-9])+([0-9]{7})\b/
    );
    let valid = pattern.test(value);
    if (!valid) {
      return false;
    } else return true;
  }

  login(data: LoginModel) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });

    const url = this.systemApiUrl.concat(Constant.AUTH_LOGIN);
    this.loginData = data;
    return this._httpClient.post(url, data, { headers: headers });
  }

  logout() {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });

    const url = this.systemApiUrl
      .concat(Constant.AUTH_LOGOUT)
      .replace('{user_id}', this.userId)
      .replace('{device_id}', this.deviceId);
    return this._httpClient.get(url, { headers: headers });
  }

  resetPassword(data: ResetPassModel) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });

    const url = this.systemApiUrl.concat(Constant.AUTH_RESET_PASS);
    return this._httpClient.post(url, data, { headers: headers });
  }

  checkValidToken(token: string) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + token,
    });

    const url = this.systemApiUrl.concat(Constant.AUTH_TOKEN_VALIDATE);
    return this._httpClient.get(url, { headers: headers });
  }

  checkToken() {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + this.token,
    });

    const url = this.systemApiUrl.concat(Constant.AUTH_TOKEN_VALIDATE);
    return this._httpClient.get(url, { headers: headers });
  }

  redirectToLoginPage() {
    if (
      !(
        this._routerService.url == '/' ||
        this._routerService.url == '/auth/login' ||
        this._routerService.url == '/auth/forget-password'
      )
    ) {
      this._toastrService.error(
        this._translateService.instant('auth_out-of-session')
      );
      this._routerService.navigate(['auth/login'], {});
    }
  }

  encryptedPassword(password: string) {
    return sha256.hex(password);
  }

  registerUser(data) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    const url = this.systemApiUrl.concat(Constant.AUTH_REGISTER);
    return this._httpClient.post(url, data, { headers: headers });
  }
}
