import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { LayerConstant } from '@core/constant/api-main/Layer_Constant';
import { Constant as MainConst } from '@core/constant/api-main/Constant';
import { Constant as SystemConst } from '@core/constant/api-system/Constant';
import { ESRReportForm, ESRReportFormVariable, GroupObjClassModel, ObjClassV2Model, ObjFieldDynModel, ObjFieldDynViewModel, ObjFieldV2Model, ObjTypeV2Model } from '@app/shared/models/main.model';
import { ConstantReport } from '@app/core/constant/api-report/Constant';
import { CacheConst } from '@app/core/constant/AnyConstant';
import { CacheAll } from '@app/core/lib/cache';
import { Constant } from '@app/core/constant/api-map/Constant';

@Injectable({
  providedIn: 'root'
})
export class MainService {
  systemApiUrl = environment.SYSTEM_API_URL;
  mainApiUrl = environment.MAIN_API_URL;
  itpApiUrl = environment.ITP_API_URL;
  catApiUrl = environment.CAT_API_URL;
  itdApiUrl = environment.ITD_API_URL;
  pbApiUrl = environment.PB_API_URL;
  dynamicApiUrl = environment.DYNAMIC_API_URL;
  hostNameOrigin = environment.HOST_NAME_ORIGIN;

  constructor(private http: HttpClient) { }

  //#region Object Class
  getListObjClass(funcCode: string, sort: string, sortBy: string, searchKey:string, hasPerm?: number, tableStatus = '', type = ''){    
    let url = this.systemApiUrl.concat(LayerConstant.LAYER_LIST_GET_LIST)
                                  .replace("{searchKey}", searchKey)
                                  .replace("{sort}", sort)
                                  .replace("{sortBy}", sortBy)
                                  .replace("{funcCode}", funcCode)
                                  .replace("{type}", type)
                                  .replace('{tableStatus}', tableStatus);
    if (typeof hasPerm == "number" && (hasPerm == 0 || hasPerm == 1)) url = url.concat("&hasPerms=" + hasPerm);

    return this.http.get(url);
  }

  getListPagingObjClass(funcCode: string, sort: string, sortBy: string, searchKey:string, pageIndex: string, pageSize: string, objClassGroupId: string, hasPerm?: number){    
    let url = this.systemApiUrl.concat(LayerConstant.LAYER_LIST_GET_LIST_PAGING)
                                  .replace("{searchKey}", searchKey)
                                  .replace("{sort}", sort)
                                  .replace("{sortBy}", sortBy)
                                  .replace("{funcCode}", funcCode)
                                  .replace("{pageIndex}", pageIndex)
                                  .replace("{pageSize}", pageSize)
                                  .replace("{objClassGroupId}", objClassGroupId)
                                  .replace("{searchKey}", searchKey);

    if (!objClassGroupId) url = url.concat('&other=true');

    if (typeof hasPerm == "number" && (hasPerm == 0 || hasPerm == 1)) url = url.concat("&hasPerms=" + hasPerm);

    return this.http.get(url);
  }

  getDetailObjClass(id: string){
    const url = this.systemApiUrl.concat(LayerConstant.LAYER_LIST_GET_DETAIL).replace("{layer_id}", id);
    return this.http.get(url);
  }

  getObjClassByCodeAndSubCode(code: string, subCode: string) {
    let url = this.systemApiUrl.concat(LayerConstant.LAYER_LIST_GET_LIST_BY_CODE)
                              .replace("{code}", code);

    if (subCode) url = url.replace("{subCode}", subCode)
    else url = url.replace("&subCode={subCode}", "")
    
    return this.http.get(url);
  }
  //#endregion

  //#region Object Field
  getListObjFields(code: string, subCode: string = "", searchKey: string = "") {
    const url = this.systemApiUrl.concat(LayerConstant.LAYER_GET_FIELDS_WITH_SEARCH).replace("{layer_code}", code)
                                                                        .replace("{layer_subCode}", subCode)
                                                                        .replace("{searchKey}", searchKey);
    return this.http.get(url);
  }

  getDetailObjField(id: string){
    const url = this.systemApiUrl.concat(LayerConstant.LAYER_GET_FIELDS_DETAILS).replace("{field_id}", id);
    return this.http.get(url);
  }

  //#endregion

  //#region Object Type
  getListObjTypes(searchKey: string){
    const url = this.catApiUrl.concat(LayerConstant.OBJ_TYPE_GET_LIST)
                                .replace("{searchKey}", searchKey);
    return this.http.get(url);
  }

  getListObjTypesByObjClass(objClassCode: string, searchKey: string){
    const url = this.catApiUrl.concat(LayerConstant.OBJ_TYPE_GET_LIST_BY_OBJ_CLASSS)
                                .replace("{objClass}", objClassCode)
                                .replace("{searchKey}", searchKey);
    return this.http.get(url);
  }

  getSortedListObjTypesByObjClass(objClass: string, sortBy: string, sortOrder: string) {
      const url = this.catApiUrl.concat(Constant.OBJ_TYPE_OBJ_CLASS)
      .replace("{objClass}", objClass)
      .replace("{sortBy}", sortBy)
      .replace("{sortOrder}", sortOrder);
      return this.http.get(url);
  }

  getListPagingObjTypes(page: number, sort: string, sortBy:string, searchKey: string){
    const url = this.catApiUrl.concat(LayerConstant.OBJ_TYPE_GET_LIST_PAGING)
                                .replace("{page}", "" + page)
                                .replace("{sort}", sort)
                                .replace("{sortBy}", sortBy)
                                .replace("{searchKey}", searchKey);
    return this.http.get(url);
  }
  //#endregion

  //#region Func Module
  getListFuncModule(){
    const url = this.systemApiUrl.concat(SystemConst.FUNC_MODULE_GET_LIST);    
    return this.http.get(url);
  }

  updateFuncModule(id, data) {
    const url = this.systemApiUrl.concat(SystemConst.FUNC_MODULE_CRUD).replace("{id}", id);    
    return this.http.put(url, data);
  }
  //#endregion

  //#region Economic Sectors
  getListEconomicSectors(sortBy: string, sortOrder: string, searchKey: string, level: string) {
    const url = this.catApiUrl.concat(MainConst.EC_GET_LIST_BY_LEVEL).replace("{sortBy}", sortBy)
                                                .replace("{sortOrder}", sortOrder)
                                                .replace("{searchKey}", searchKey)
                                                .replace("{level}", level);
    return this.http.get(url);
  }
  
  getListSupportSectors(sortBy: string, sortOrder: string, searchKey: string) {
    const url = this.catApiUrl.concat(MainConst.SUPPORT_INDUSTRY_PARENT_LIST).replace("{sortBy}", sortBy)
                                                .replace("{sortOrder}", sortOrder)
                                                .replace("{searchKey}", searchKey);
    return this.http.get(url);
  }

  getListEconomicSectorChilds(sortBy: string, sortOrder: string, searchKey: string, parentId: string) {
    const url = this.catApiUrl.concat(MainConst.EC_GET_CHILDREN).replace("{sortBy}", sortBy)
                                                .replace("{sortOrder}", sortOrder)
                                                .replace("{searchKey}", searchKey)
                                                .replace("{parentId}", parentId);
    return this.http.get(url);
  }

  getListByApiUrl(apiUrl: string){    
    let url = apiUrl.replace('{MainApiURL}/', this.mainApiUrl)
    .replace('{SystemApiURL}/', this.systemApiUrl)
    .replace('{CatApiURL}/', this.catApiUrl)
    .replace('{ItdApiURL}/', this.itdApiUrl)
    .replace('{ItpApiURL}/', this.itpApiUrl)
    .replace('{PbApiURL}/', this.pbApiUrl)
    .replace('{DynamicApiURL}/', this.dynamicApiUrl);

    try {
      return this.http.get(new URL(url).href);
    } catch (error) {
      return this.http.get(new URL(this.hostNameOrigin + '/' + url).href);
    }
  }
  //#endregion

  //#region BaseEnt
  getAllBaseEnt(){
    let url = this.itdApiUrl.concat(LayerConstant.BASE_ENT_GET_LIST).replace("objClassCode={layer_code}&objClassSubCode={layer_subCode}&", "");
    return this.http.get(url);
  }

  getListBaseEnt(layerCode: string, layerSubCode: string){
    let url = this.itdApiUrl.concat(LayerConstant.BASE_ENT_GET_LIST).replace("{layer_code}", layerCode);
    if (layerSubCode) url = url.replace("{layer_subCode}", layerSubCode);
    else url = url.replace("&objClassSubCode={layer_subCode}", "");

    return this.http.get(url);
  }

  getListPagingBaseEnt(page: number, sort: string, sortBy: string, searchKey: string){    
    const url = this.itdApiUrl.concat(LayerConstant.BASE_ENT_GET_LIST_PAGING)
                                  .replace("{page}", "" + page)
                                  .replace("{sort}", sort)
                                  .replace("{sortBy}", sortBy)
                                  .replace("{searchKey}", searchKey);

    return this.http.get(url);
  }

  getBaseEntById(id: string){
  //   const url = this.itdApiUrl.concat(LayerConstant.BASE_ENT_GET_DETAIL).replace("{id}", id);
  //   return this.http.get(url);
  // }

  // getBaseEntByCode(code: string){
  //   const url = this.itdApiUrl.concat(LayerConstant.BASE_ENT_GET_DETAIL_BY_CODE).replace("{code}", code);
    const url = this.itdApiUrl.concat(LayerConstant.BASE_ENT_GET_DETAIL).replace("{id}", id);
    return this.http.get(url);
  }

  getBaseEntByCode(code: string, layerCode: string, layerSubcode: string = null){
    let url = this.itdApiUrl.concat(LayerConstant.BASE_ENT_GET_DETAIL_BY_CODE)
      .replace("{code}", code)
      .replace("{layerCode}", layerCode);

    if (layerSubcode || layerSubcode !== '') {
      url = url.replace("{layerSubcode}", layerSubcode);
    } else {
      url = url.replace("&objClassSubCode={layerSubcode}", "");
    }
    return this.http.get(url);
  }

  getBaseEntByBusinessCodev2(businessCode: string, layerCode: string, layerSubcode: string = null){
    let url = this.itdApiUrl.concat(LayerConstant.BASE_ENT_GET_DETAIL_BY_BUSINESS_CODE)
      .replace("{businessCode}", businessCode)
      .replace("{layerCode}", layerCode);

    if (layerSubcode || layerSubcode !== '') {
      url = url.replace("{layerSubcode}", layerSubcode);
    } else {
      url = url.replace("&objClassSubCode={layerSubcode}", "");
    }
    return this.http.get(url);
  }

  getBaseEntByBusinessCode(businessCode: string){
    const url = this.itdApiUrl.concat(LayerConstant.BASE_ENT_GET_DETAIL_BY_BUSINESS_CODE).replace("{businessCode}", businessCode);
    return this.http.get(url);
  }

  public getChildDataByCode(tableCode: string, code: string, searchKey: string) {
    const url = this.mainApiUrl.concat(LayerConstant.LINK_TABLE_GET_LIST_BY_CODE)
                             .replace("{table_code}", tableCode)
                             .replace("{code}", code)
                             .replace("{searchKey}", searchKey);

    return this.http.get(url);
  }

  public getLayerDataByCode(code: string, layerCode: string, layerSubCode: string) {
    let url = this.mainApiUrl.concat(LayerConstant.LAYER_GET_LIST_DATA_BY_CODE)
                             .replace("{layer_code}", layerCode)
                             .replace("{code}", code);
    
    if (layerSubCode) url = url.replace("{layer_subCode}", layerSubCode);
    else url = url.replace("/{layer_subCode}", "");

    return this.http.get(url);
  }
  //#endregion

  public getMenuListTree() {
    const url = this.systemApiUrl.concat(LayerConstant.MENU_LIST_TREE);
    return this.http.get(url);
  }

  public getMenuById(id) {
    const url = this.systemApiUrl.concat(LayerConstant.MENU_CRUD).concat(`/${id}`);
    return this.http.get(url);
  }

  public getListMenuByPermissionGroup(id = '', sortOrder = 'asc', sortBy = 'seq') {
    const url = this.systemApiUrl.concat(LayerConstant.MENU_PERMISSION_PERMISSIONGROUP)
    .replace('{id}', id)
    .replace('{sortOrder}', sortOrder)
    .replace('{sortBy}', sortBy);
    return this.http.get(url);
  }

  public getListMenuByToken(sortOrder = 'asc', sortBy = 'seq') {
    const url = this.systemApiUrl.concat(LayerConstant.MENU_PERMISSION_BY_TOKEN)
    .replace('{sortOrder}', sortOrder)
    .replace('{sortBy}', sortBy);
    return this.http.get(url);
  }

  public createMenu(data) {
    const url = this.systemApiUrl.concat(LayerConstant.MENU_CRUD);
    return this.http.post(url, data);
  }

  public updateMenu(data) {
    const url = this.systemApiUrl.concat(LayerConstant.MENU_CRUD).concat(`/${data.id}`);
    return this.http.put(url, data);
  }

  public deleteMenu(id) {
    const url = this.systemApiUrl.concat(LayerConstant.MENU_CRUD).concat(`/${id}/cascade`);
    return this.http.delete(url);
  }

  public activeMenu(id) {
    const url = this.systemApiUrl.concat(LayerConstant.MENU_ACTIVE);
    return this.http.put(url, id);
  }

  public createMenuPermission(data) {
    const url = this.systemApiUrl.concat(LayerConstant.MENU_PERMISSION_CRUD);
    return this.http.post(url, data);
  }

  public updateMenuPermission(data) {
    const url = this.systemApiUrl.concat(LayerConstant.MENU_PERMISSION_CRUD).concat(`/${data.id}`);
    return this.http.put(url, data);
  }

  public deleteMenuPermission(id) {
    const url = this.systemApiUrl.concat(LayerConstant.MENU_PERMISSION_CRUD).concat(`/${id}`);
    return this.http.delete(url);
  }

  public deleteMenuPermissionCascade(id) {
    const url = this.systemApiUrl.concat(LayerConstant.MENU_PERMISSION_DELETE_CASCADE).replace('{id}', id);
    return this.http.delete(url);
  }

  public checkMenuPermission(path = '') {
    const url = this.systemApiUrl.concat(LayerConstant.MENU_PERMISSION_ACCEPT).replace('{path}', path);
    return this.http.get(url);
  }

  public getListGroupObjClassV2(sortBy = 'seq', sortOrder = 'asc') {
    const url = this.systemApiUrl.concat(LayerConstant.GROUP_OBJ_CLASS_V2_GET_LIST)
    .replace('{sortBy}', sortBy)
    .replace('{sortOrder}', sortOrder);
    return this.http.get(url);
  }

  public getListPagingGroupObjClassV2(sortBy = 'seq', sortOrder = 'asc', pageIndex = '1', pageSize = '10', searchKey = '') {
    const url = this.systemApiUrl.concat(LayerConstant.GROUP_OBJ_CLASS_V2_GET_LIST_PAGING)
    .replace('{sortBy}', sortBy)
    .replace('{sortOrder}', sortOrder)
    .replace('{pageIndex}', pageIndex)
    .replace('{pageSize}', pageSize)
    .replace('{searchKey}', searchKey);
    return this.http.get(url);
  }

  public getByIdGroupObjClassV2(id: string) {
    const url = this.systemApiUrl.concat(LayerConstant.GROUP_OBJ_CLASS_V2_CRUD).concat(`/${id}/`);
    return this.http.get(url);
  }

  public createGroupObjClassV2(data: GroupObjClassModel) {
    const url = this.systemApiUrl.concat(LayerConstant.GROUP_OBJ_CLASS_V2_CRUD);
    return this.http.post(url, data);
  }

  public updateGroupObjClassV2(data: GroupObjClassModel) {
    const url = this.systemApiUrl.concat(LayerConstant.GROUP_OBJ_CLASS_V2_CRUD).concat(`/${data.id}`);
    return this.http.put(url, data);
  }

  public deleteGroupObjClassV2(id: string) {
    const url = this.systemApiUrl.concat(LayerConstant.GROUP_OBJ_CLASS_V2_CRUD).concat(`/${id}`);
    return this.http.delete(url);
  }

  public getListPagingObjClassV2(objClassGroupId = '', sortBy = 'seq', sortOrder = 'asc', pageIndex = '1', pageSize = '10', searchKey = '', funcModuleCodes = '') {
    const url = this.dynamicApiUrl.concat(LayerConstant.OBJ_CLASS_V2_GET_LIST_PAGING)
    .replace('{sortBy}', sortBy)
    .replace('{sortOrder}', sortOrder)
    .replace('{pageIndex}', pageIndex)
    .replace('{pageSize}', pageSize)
    .replace('{searchKey}', searchKey)
    .replace('{objClassGroupId}', objClassGroupId)
    .replace('{funcModuleCodes}', funcModuleCodes)
    return this.http.get(url);
  }

  public getListPagingObjClassV2ByParentObjClassCode(parentObjClassCode = '', sortBy = 'seq', sortOrder = 'asc', pageIndex = '1', pageSize = '10', searchKey = '') {
    const url = this.dynamicApiUrl.concat(LayerConstant.OBJ_CLASS_V2_GET_LIST_PAGING_BY_PARENT_CODE)
    .replace('{sortBy}', sortBy)
    .replace('{sortOrder}', sortOrder)
    .replace('{pageIndex}', pageIndex)
    .replace('{pageSize}', pageSize)
    .replace('{searchKey}', searchKey)
    .replace('{parentObjClassCode}', parentObjClassCode);
    return this.http.get(url);
  }

  public getListPagingObjClassV2ByOtherGroup(sortBy = 'seq', sortOrder = 'asc', pageIndex = '1', pageSize = '10', searchKey = '', funcModuleCodes = '') {
    const url = this.dynamicApiUrl.concat(LayerConstant.OBJ_CLASS_V2_GET_LIST_OTHER_PAGING)
    .replace('{sortBy}', sortBy)
    .replace('{sortOrder}', sortOrder)
    .replace('{pageIndex}', pageIndex)
    .replace('{pageSize}', pageSize)
    .replace('{searchKey}', searchKey)
    .replace('{funcModuleCodes}', funcModuleCodes);
    return this.http.get(url);
  }

  public getListObjClassV2ByParentCode(parentObjClassCode = '',sortBy = 'name', sortOrder = 'asc', searchKey='') {
    const url = this.dynamicApiUrl.concat(LayerConstant.OBJ_CLASS_V2_GET_LIST_BY_PARENT_CODE)
    .replace('{sortBy}', sortBy)
    .replace('{sortOrder}', sortOrder)
    .replace('{parentObjClassCode}', parentObjClassCode)
    .replace('{searchKey}', searchKey);
    return this.http.get(url);
  }

  public getListObjClassV2ByFuncModuleCodes(parentObjClassCode = '',sortBy = 'name', sortOrder = 'asc', searchKey='', funcModuleCodes = '') {
    const url = this.dynamicApiUrl.concat(LayerConstant.OBJ_CLASS_V2_GET_LIST_BY_FUNC_MODULE_CODE)
    .replace('{sortBy}', sortBy)
    .replace('{sortOrder}', sortOrder)
    .replace('{parentObjClassCode}', parentObjClassCode)
    .replace('{searchKey}', searchKey)
    .replace('{funcModuleCodes}', funcModuleCodes);
    return this.http.get(url);
  }

  public getListObjClassV2(objClassGroupId = '', funcModuleCodes = '', sortBy = 'name', sortOrder = 'asc', searchKey = '', tableStatus = '') {
    const url = this.dynamicApiUrl.concat(LayerConstant.OBJ_CLASS_V2_GET_LIST)
    .replace('{sortBy}', sortBy)
    .replace('{sortOrder}', sortOrder)
    .replace('{objClassGroupId}', objClassGroupId)
    .replace('{funcModuleCodes}', funcModuleCodes)
    .replace('{tableStatus}', tableStatus)
    .replace('{searchKey}', searchKey);
    return this.http.get(url);
  }

  public getListObjClassV2ByOtherGroup(funcModuleCodes = '', sortBy = 'name', sortOrder = 'asc', searchKey = '', tableStatus = '') {
    const url = this.dynamicApiUrl.concat(LayerConstant.OBJ_CLASS_V2_GET_LIST_BY_OTHER_GROUP)
    .replace('{sortBy}', sortBy)
    .replace('{sortOrder}', sortOrder)
    .replace('{funcModuleCodes}', funcModuleCodes)
    .replace('{tableStatus}', tableStatus)
    .replace('{searchKey}', searchKey);
    return this.http.get(url);
  }

  public getObjClassV2ById(id) {
    const url = this.dynamicApiUrl.concat(LayerConstant.OBJ_CLASS_V2_CRUD).concat(`/${id}`);
    return this.http.get(url);
  }

  public cloneObjClassV2(data) {
    const url = this.dynamicApiUrl.concat(LayerConstant.OBJ_CLASS_V2_CLONE);
    return this.http.post(url, data);
  }

  public createObjClassV2(data: ObjClassV2Model) {
    const url = this.dynamicApiUrl.concat(LayerConstant.OBJ_CLASS_V2_CRUD);
    return this.http.post(url, data);
  }

  public updateObjClassV2(data: ObjClassV2Model) {
    const url = this.dynamicApiUrl.concat(LayerConstant.OBJ_CLASS_V2_CRUD).concat(`/${data.id}`);
    return this.http.put(url, data);
  }

  public deleteObjClassV2(id) {
    const url = this.dynamicApiUrl.concat(LayerConstant.OBJ_CLASS_V2_CRUD).concat(`/${id}`);
    return this.http.delete(url);
  }

  public getListPagingObjFieldV2(sortBy = 'name', sortOrder = 'asc', searchKey = '', pageIndex = '1', pageSize = '10', objClassCode = '', objClassSubCode = '') {
    const url = this.dynamicApiUrl.concat(LayerConstant.OBJ_FIELD_V2_GET_LIST_PAGING)
    .replace('{sortBy}', sortBy)
    .replace('{sortOrder}', sortOrder)
    .replace('{searchKey}', searchKey)
    .replace('{pageIndex}', pageIndex)
    .replace('{pageSize}', pageSize)
    .replace('{objClassCode}', objClassCode)
    .replace('{objClassSubCode}', objClassSubCode);
    return this.http.get(url);
  }

  public getListPagingObjFieldV2ByCode(sortBy = 'name', sortOrder = 'asc', searchKey = '', pageIndex = '1', pageSize = '10', objClassCode = '', objClassSubCode = '', level = '1') {
    const url = this.dynamicApiUrl.concat(LayerConstant.OBJ_FIELD_V2_GET_LIST_PAGING_BY_CODE)
    .replace('{sortBy}', sortBy)
    .replace('{sortOrder}', sortOrder)
    .replace('{searchKey}', searchKey)
    .replace('{pageIndex}', pageIndex)
    .replace('{pageSize}', pageSize)
    .replace('{objClassCode}', objClassCode)
    .replace('{objClassSubCode}', objClassSubCode)
    .replace('{level}', level);
    return this.http.get(url);
  }

  public getListObjFieldV2(sortBy = 'seqNum', sortOrder = 'asc', objClassCode = '', objClassSubCode = '') {
    const url = this.dynamicApiUrl.concat(LayerConstant.OBJ_FIELD_V2_GET_LIST)
    .replace('{sortBy}', sortBy)
    .replace('{sortOrder}', sortOrder)
    .replace('{objClassCode}', objClassCode)
    .replace('{objClassSubCode}', objClassSubCode);
    return this.http.get(url);
  }

  public createObjFieldV2(data: ObjFieldV2Model) {
    const url = this.dynamicApiUrl.concat(LayerConstant.OBJ_FIELD_V2_CRUD);
    return this.http.post(url, data);
  }

  public updateObjFieldV2(data: ObjFieldV2Model) {
    const url = this.dynamicApiUrl.concat(LayerConstant.OBJ_FIELD_V2_CRUD).concat(`/${data.id}`);
    return this.http.put(url, data);
  }

  public deleteObjFieldV2(id) {
    const url = this.dynamicApiUrl.concat(LayerConstant.OBJ_FIELD_V2_CRUD).concat(`/${id}`);
    return this.http.delete(url);
  }

  public createObjTypeV2(data: ObjTypeV2Model) {
    const url = this.catApiUrl.concat(LayerConstant.OBJ_TYPE_V2_CRUD);
    return this.http.post(url, data);
  }

  public updateObjTypeV2(data: ObjTypeV2Model) {
    const url = this.catApiUrl.concat(LayerConstant.OBJ_TYPE_V2_CRUD).concat(`/${data.id}`);
    return this.http.put(url, data);
  }

  public deleteObjTypeV2(id) {
    const url = this.catApiUrl.concat(LayerConstant.OBJ_TYPE_V2_CRUD).concat(`/${id}`);
    return this.http.delete(url);
  }

  //#region ESRReportForm

  public ESRReportForm_GetListPaging(sortBy = 'name', sortOrder = 'asc', pageIndex = 1, pageSize = 10, searchKey = '') {
    const url = this.mainApiUrl.concat(ConstantReport.ESR_REPORT_FORM_GET_LIST_PAGING)
    .replace('{sortBy}', sortBy)
    .replace('{sortOrder}', sortOrder)
    .replace('{pageIndex}', pageIndex.toString())
    .replace('{pageSize}', pageSize.toString())
    .replace('{searchKey}', searchKey);
    
    return this.http.get(url);
  }

  public ESRReportForm_GetList() {
    const url = this.mainApiUrl.concat(ConstantReport.ESR_REPORT_FORM_CRUD);
    
    return this.http.get(url);
  }

  public ESRReportForm_GetById(id: string) {
    const url = this.mainApiUrl.concat(ConstantReport.ESR_REPORT_FORM_CRUD).concat('/' + id);
    
    return this.http.get(url);
  }

  public ESRReportForm_Create(data: ESRReportForm) {
    const url = this.mainApiUrl.concat(ConstantReport.ESR_REPORT_FORM_CRUD);
    
    return this.http.post(url, data);
  }

  public ESRReportForm_Update(id: string, data: ESRReportForm) {
    const url = this.mainApiUrl.concat(ConstantReport.ESR_REPORT_FORM_CRUD).concat('/' + id);
    
    return this.http.put(url, data);
  }

  public ESRReportForm_Delete(id: string) {
    const url = this.mainApiUrl.concat(ConstantReport.ESR_REPORT_FORM_CRUD).concat('/' + id);
    
    return this.http.delete(url);
  }

  public ESRReportForm_ExportExcel(code: string) {
    const token = CacheAll.getCookie(CacheConst.USER_TOKEN);
    const headers = new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token
    });
    const url = this.mainApiUrl.concat(ConstantReport.ESR_REPORT_FORM_EXPORT_EXCEL).replace('{code}', code);
    
    return this.http.get(url, { headers: headers, responseType: 'blob' });
  }

  //#endregion

  //#region ESRReportFormFormula

  public ESRReportFormFormula_GetListPaging(sortBy = 'name', sortOrder = 'asc', pageIndex = 1, pageSize = 10, searchKey = '') {
    const url = this.mainApiUrl.concat(ConstantReport.ESR_REPORT_FORM_VARIABLE_GET_LIST_PAGING)
    .replace('{sortBy}', sortBy)
    .replace('{sortOrder}', sortOrder)
    .replace('{pageIndex}', pageIndex.toString())
    .replace('{pageSize}', pageSize.toString())
    .replace('{searchKey}', searchKey);
    
    return this.http.get(url);
  }

  public ESRReportFormVariable_GetList() {
    const url = this.mainApiUrl.concat(ConstantReport.ESR_REPORT_FORM_VARIABLE_CRUD);
    
    return this.http.get(url);
  }

  public ESRReportFormVariable_GetListByFormCode(code: string) {
    const url = this.mainApiUrl.concat(ConstantReport.ESR_REPORT_FORM_VARIABLE_CRUD).concat('?code=' + code);
    
    return this.http.get(url);
  }

  public ESRReportFormVariable_GetById(id: string) {
    const url = this.mainApiUrl.concat(ConstantReport.ESR_REPORT_FORM_VARIABLE_CRUD).concat('/' + id);
    
    return this.http.get(url);
  }

  public ESRReportFormVariable_Create(data: ESRReportFormVariable) {
    const url = this.mainApiUrl.concat(ConstantReport.ESR_REPORT_FORM_VARIABLE_CRUD);
    
    return this.http.post(url, data);
  }

  public ESRReportFormVariable_Update(id: string, data: ESRReportFormVariable) {
    const url = this.mainApiUrl.concat(ConstantReport.ESR_REPORT_FORM_VARIABLE_CRUD).concat('/' + id);
    
    return this.http.put(url, data);
  }

  public ESRReportFormVariable_Delete(id: string) {
    const url = this.mainApiUrl.concat(ConstantReport.ESR_REPORT_FORM_VARIABLE_CRUD).concat('/' + id);
    
    return this.http.delete(url);
  }

  public ESRReportFormVariable_PreviewById(id: string) {
    const url = this.mainApiUrl.concat(ConstantReport.ESR_REPORT_FORM_VARIABLE_PREVIEW).replace('{id}', id);
    
    return this.http.get(url);
  }

  //#endregion
}
