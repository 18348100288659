import {
    HttpEvent,
    HttpHandler,
    HttpRequest,
    HttpErrorResponse,
    HttpInterceptor,
    HTTP_INTERCEPTORS
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, retry } from 'rxjs/operators';
import { HandleErrorModel } from '@shared/models/global.model';

export class ErrorInterceptor implements HttpInterceptor {
    public intercept(
        request: HttpRequest<any>,
        next: HttpHandler
    ): Observable<HttpEvent<any>> {
        return next.handle(request)
            .pipe(
                retry(1),
                catchError((error: HttpErrorResponse) => {
                    let errorMessage: HandleErrorModel;
                    if (error.error instanceof ErrorEvent) {
                    // A client-side or network error occurred. Handle it accordingly.
                        errorMessage = {
                            code: 0,
                            message: `Có lỗi xảy ra: ${error.error.message}`
                        };
                    } else {

                        if (error.url.includes('NotificationLog/ofMine/listPaging'))
                            return;

                        // The backend returned an unsuccessful response code.
                        let currentMsg = "";
                        switch (error.status) {
                            case 400:
                                currentMsg = "Yêu cầu không hợp lệ.";
                            break;
                            case 401:
                                currentMsg = "Vui lòng đăng nhập trước khi sử dụng phần mềm!";
                            break;
                            case 403:
                                currentMsg = "Không có quyền truy cập!";
                            break;
                            case 404:
                                currentMsg = "Tài nguyên được yêu cầu không tồn tại.";
                            break;
                            case 412:
                                currentMsg = "Không thể kết nối đến máy chủ.";
                            break;
                            case 413:
                                currentMsg = "Yêu cầu lớn hơn mức mà máy chủ có thể xử lý.";
                            break;
                            case 415:
                                currentMsg = "Định dạng không được hỗ trợ.";
                            break;
                            case 500:
                                currentMsg = "Lỗi máy chủ nội bộ. Vui lòng liên hệ Quản trị viên để khắc phục lỗi!";
                            break;
                            case 503:
                                currentMsg = "Dịch vụ được yêu cầu không có sẵn.";
                            break;
                            case 422:
                                currentMsg = "Xác thực không thành công!";
                            break;
                            default:
                                currentMsg = "Lỗi hệ thống. Vui lòng liên hệ Quản trị viên để khắc phục lỗi!";
                        }
                        errorMessage = {
                            code: error.status,
                            message: currentMsg
                        }
                    }
                    return throwError(errorMessage);
                })
            )
    }
}
/**
 * Provider POJO for the interceptor
 */
export const ErrorInterceptorProvider = {
    provide: HTTP_INTERCEPTORS,
    useClass: ErrorInterceptor,
    multi: true,
};