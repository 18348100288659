export const Constant = {
    AUTH_LOGIN: 'login',
    AUTH_RESET_PASS: 'resetPassword',
    //SYSTEM MANAGEMENT USER
    SM_USER_CREATE: 'user',
    SM_USER_DELETE: 'user/{user_id}',
    SM_USER_UPDATE: 'user/{user_id}',
    SM_USER_DETAIL: 'user/{user_id}',
    SM_USER_GET_LIST: 'user',
    SM_USER_LIST_PAGING: 'user/listPaging',
    SM_USER_CHANGE_PASS: 'user/changePassword/{user_id}',

    SORT_BY: '?sortBy={sortby}',
    SEARCH_KEY: '?sortBy={sortBy}&sortOrder={sortOrder}&searchKey={searchKey}',
    CATEGORY_IMPORT_EXCEL: '{category}/ImportExcel',
    // ADMINISTRATIVE UNIT CATEGORY
    PROVINCE: 'Province?sortBy={sortBy}&sortOrder={sortOrder}&searchKey={searchKey}',
    PROVINCE_CREATE: 'Province',
    PROVINCE_UPDATE: 'Province/{id}',
    DISTRICT_OF: 'District/of/{code}?sortBy={sortBy}&sortOrder={sortOrder}&searchKey={searchKey}',
    DISTRICT_GET: 'District',
    DISTRICT_CREATE: 'District',
    DISTRICT_UPDATE: 'District/{id}',
    WARD_OF: 'Ward/of/{code}?sortBy={sortBy}&sortOrder={sortOrder}&searchKey={searchKey}',
    WARD_BY: 'Ward/by/{code}?sortBy={sortBy}&sortOrder={sortOrder}&searchKey={searchKey}',
    WARD_GET: 'Ward',
    WARD_CREATE: 'Ward',
    WARD_UPDATE: 'Ward/{id}',
    // ETHNIC CATEGORY
    ETH_GET_LIST: "Ethnic?sortBy={sortBy}&sortOrder={sortOrder}&searchKey={searchKey}",
    ETH_GET_BY_ID: "Ethnic/{id}",
    ETH_LIST_PAGING: "Ethnic/listPaging?pageIndex={page}&sortBy={sortBy}&sortOrder={sort}&searchKey={searchKey}",
    ETH_CREATE: 'Ethnic',
    ETH_UPDATE: 'Ethnic/{id}',
    // RELIGION CATEGORY
    RC_GET_LIST: "Religion?sortBy={sortBy}&sortOrder={sortOrder}&searchKey={searchKey}",
    RC_GET_BY_ID: "Religion/{id}",
    RC_LIST_PAGING: "Religion/listPaging?pageIndex={page}&sortBy={sortBy}&sortOrder={sort}&searchKey={searchKey}",
    RC_CREATE: 'Religion',
    RC_UPDATE: 'Religion/{id}',
    //NATINALITY CATEGORY
    NATINALITY_LIST: 'Nationality',
    NATINALITY_SEARCH: "Nationality?sortBy={sortBy}&sortOrder={sortOrder}&searchKey={searchKey}",
    NATINALITY_DETAIL: 'nationality/{id}',
    NATINALITY_CREATE: 'Nationality',
    NATINALITY_UPDATE: 'Nationality/{id}',

    //GENDER CATEGORY
    GENDER_LIST: 'Gender',
    GENDER_SEARCH: "Gender?sortBy={sortBy}&sortOrder={sortOrder}&searchKey={searchKey}",
    GENDER_CREATE: 'Gender',
    GENDER_UPDATE: 'Gender/{id}',
    // ECONOMIC CATEGORY
    EC_GET_LIST: "EconomicSector?sortBy={sortBy}&sortOrder={sortOrder}&searchKey={searchKey}",
    EC_GET_LIST_BY_LEVEL: "EconomicSector?sortBy={sortBy}&sortOrder={sortOrder}&searchKey={searchKey}&level={level}",
    EC_LIST_PAGING: "EconomicSector/listPaging",
    EC_SEARCH: "EconomicSector?sortBy={sortBy}&sortOrder={sortOrder}&searchKey={searchKey}",
    EC_CREATE: "EconomicSector",
    EC_UPDATE: "EconomicSector/{id}",
    EC_DELETE: "EconomicSector/{id}",
    EC_GET_BY_ID: "EconomicSector/{id}",
    EC_GET_BY_PARENTCODE: "EconomicSector/of/{id}",
    EC_GET_HIDE: 'EconomicSector/hide/{userId}?sortBy={sortBy}&sortOrder={sortOrder}&searchKey={searchKey}',
    EC_GET_SHOW: 'EconomicSector/show/{userId}?sortBy={sortBy}&sortOrder={sortOrder}&searchKey={searchKey}',
    EC_GET_CHILDREN: 'EconomicSector/children/{parentId}?sortBy={sortBy}&sortOrder={sortOrder}&searchKey={searchKey}',

    // ADMSTRPROCED
    AF_GET_LIST: "AdmstrProced?sortBy={sortBy}&sortOrder={sortOrder}&searchKey={searchKey}",
    AF_GET_BY_ID: "AdmstrProced/{id}",
    ADMSTRPROCED_CREATE: "AdmstrProced",
    ADMSTRPROCED_UPDATE: "AdmstrProced/{id}",
    LIST_ADMSTR_PROCED_BY_ADMSTR_PROCED_SECTOR_ID: 'AdmstrProced?admstrProcedSectorId={admstrProcedSectorId}&sortBy={sortBy}&sortOrder={sortOrder}&searchKey={searchKey}',
    GET_ALL_ADMSTR_PROCED: "AdmstrProced/get?sortBy={sortBy}&sortOrder={sortOrder}&searchKey={searchKey}",

    // ADMSTRPROCED SECTOR
    AS_GET_LIST: "AdmstrProcedSector?sortBy={sortBy}&sortOrder={sortOrder}&searchKey={searchKey}",
    AS_GET_SEARCH: "AdmstrProcedSector?sortBy={sortBy}&sortOrder={sortOrder}&searchKey={searchKey}",
    AS_GET_BY_ID: "AdmstrProcedSector/{id}",
    AS_CREATE: 'AdmstrProcedSector',
    AS_UPDATE: 'AdmstrProcedSector/{id}',
    
    //LEGALDOCTYPE CATEGORY
    LEGALDOCTYPE_LIST: "LegalDocType?sortBy={sortBy}&sortOrder={sortOrder}&searchKey={searchKey}",
    LEGALDOCTYPE_DETAIL: 'LegalDocType/{id}',
    LEGALDOCTYPE_CRUD: 'LegalDocType/{id}',
    
    //ADMSTRDOCTYPE CATEGORY
    ADMSTRDOCTYPE_LIST: 'AdmstrDocType',
    ADMSTRDOCTYPE_SEARCH: "AdmstrDocType?sortBy={sortBy}&sortOrder={sortOrder}&searchKey={searchKey}",
    ADMSTRDOCTYPE_DETAIL: 'AdmstrDocType/{id}',
    ADMSTRDOCTYPE_CRUD: 'AdmstrDocType/{id}',

    //STATEAGENCY CATEGORY
    STATEAGENCY_LIST: "StateAgency?sortBy={sortBy}&sortOrder={sortOrder}&searchKey={searchKey}",
    STATEAGENCY_DETAIL: 'StateAgency/{id}',
    STATEAGENCY_CREATE: 'StateAgency',
    STATEAGENCY_UPDATE: 'StateAgency/{id}',
    STATEAGENCY_GET_BY_PARENTCODE: "StateAgency/of/{id}",

    //LEGALDOC CATEGORY
    LEGALDOC_LIST: "LegalDoc?sortBy={sortBy}&sortOrder={sortOrder}&searchKey={searchKey}",
    LEGALDOC_LIST_PAGING: "LegalDoc/listPaging?pageIndex={page}&sortOrder={sortOrder}&sortBy={sortBy}&searchKey={searchKey}",
    LEGALDOC_CRUD: 'LegalDoc/{id}',

    //LEGALDOC CATEGORY
    PROMULGATE_AGENCY_LIST: "PromulgateAgency?sortBy={sortBy}&sortOrder={sortOrder}&searchKey={searchKey}",
    PROMULGATE_AGENCY_LIST_PAGING: "PromulgateAgency/listPaging?pageIndex={page}&sortOrder={sort}&sortBy={sortBy}&searchKey={searchKey}",
    PROMULGATE_AGENCY_CRUD: 'PromulgateAgency/{id}',

    // PRODUCT SECTOR CATEGORY
    PRODUCTSECTOR_LIST: "ProductSector",
    PRODUCTSECTOR_LIST_PAGING: "ProductSector/listPaging",
    PRODUCTSECTOR_SEARCH: "ProductSector?sortBy={sortBy}&sortOrder={sortOrder}&searchKey={searchKey}",
    PRODUCTSECTOR_CREATE: "ProductSector",
    PRODUCTSECTOR_UPDATE: "ProductSector/{id}",
    PRODUCTSECTOR_DELETE: "ProductSector/{id}",
    PRODUCTSECTOR_GET_BY_ID: "ProductSector/{id}",
    PRODUCTSECTOR_GET_BY_PARENTCODE: "ProductSector/of/{id}",
    PRODUCTSECTOR_GET_HIDE: 'ProductSector/hide/{userId}?sortBy={sortBy}&sortOrder={sortOrder}&searchKey={searchKey}',
    PRODUCTSECTOR_GET_SHOW: 'ProductSector/show/{userId}?sortBy={sortBy}&sortOrder={sortOrder}&searchKey={searchKey}',
    PRODUCTSECTOR_GET_CHILDREN: 'ProductSector/children/{parentId}?sortBy={sortBy}&sortOrder={sortOrder}&searchKey={searchKey}',


    // ADMSTRPROCEDLEVEL
    ADMSTRPROCEDLEVEL_GET_LIST: 'AdmstrProcedLevel?sortBy={sortBy}&sortOrder={sortOrder}&searchKey={searchKey}',

    // EXIM GOOD CATEGORY
    EXIMGOOD_LIST: "ExportImportGood?sortBy={sortBy}&sortOrder={sortOrder}&searchKey={searchKey}",
    EXIMGOOD_LIST_PAGING: "ExportImportGood/listPaging",
    EXIMGOOD_SEARCH: "ExportImportGood?sortBy={sortBy}&sortOrder={sortOrder}&searchKey={searchKey}",
    EXIMGOOD_CREATE: "ExportImportGood",
    EXIMGOOD_UPDATE: "ExportImportGood/{id}",
    EXIMGOOD_DELETE: "ExportImportGood/{id}",
    EXIMGOOD_GET_BY_ID: "ExportImportGood/{id}",
    EXIMGOOD_GET_BY_PARENTCODE: "ExportImportGood/of/{id}",
    EXIMGOOD_GET_HIDE: 'ExportImportGood/hide/{userId}?sortBy={sortBy}&sortOrder={sortOrder}&searchKey={searchKey}',
    EXIMGOOD_GET_SHOW: 'ExportImportGood/show/{userId}?sortBy={sortBy}&sortOrder={sortOrder}&searchKey={searchKey}',
    EXIMGOOD_GET_CHILDREN: 'ExportImportGood/children/{parentId}?sortBy={sortBy}&sortOrder={sortOrder}&searchKey={searchKey}',


    // BUSINESS ADMS UNIT TYPE
    BUSINESSADMSUNITTYPE_LIST: 'BusinessAdmstrUnitType?sortBy={sortBy}&sortOrder={sortOrder}&searchKey={searchKey}',
    BUSINESSADMSUNITTYPE_DETAIL: 'BusinessAdmstrUnitType/{id}',
    BUSINESSADMSUNITTYPE_CREATE: "BusinessAdmstrUnitType",
    BUSINESSADMSUNITTYPE_UPDATE: "BusinessAdmstrUnitType/{id}",
    BUSINESSADMSUNITTYPE_DELETE: "BusinessAdmstrUnitType/{id}",
    BUSINESSADMSUNITTYPE_GET_HIDE: 'BusinessAdmstrUnitType/hide/{userId}?sortBy={sortBy}&sortOrder={sortOrder}&searchKey={searchKey}',
    BUSINESSADMSUNITTYPE_GET_SHOW: 'BusinessAdmstrUnitType/show/{userId}?sortBy={sortBy}&sortOrder={sortOrder}&searchKey={searchKey}',

    // INDUSTRIALFIELD
    INDUSTRIALFIELD_LIST:   'IndustrialField',
    INDUSTRIALFIELD_SEARCH: "IndustrialField?sortBy={sortBy}&sortOrder={sortOrder}&searchKey={searchKey}",
    INDUSTRIALFIELD_DETAIL: 'IndustrialField/{id}',
    INDUSTRIALFIELD_CREATE: "IndustrialField",
    INDUSTRIALFIELD_UPDATE: "IndustrialField/{id}",
    INDUSTRIALFIELD_UPDATE_LIST: "IndustrialField",
    INDUSTRIALFIELD_DELETE: "IndustrialField/{id}",
    INDUSTRIALFIELD_GET_HIDE: 'IndustrialField?sortBy={sortBy}&sortOrder={sortOrder}&searchKey={searchKey}&show=0',
    INDUSTRIALFIELD_GET_SHOW: 'IndustrialField?sortBy={sortBy}&sortOrder={sortOrder}&searchKey={searchKey}&show=1',

    // COMMERCIALFIELD
    COMMERCIALFIELD_LIST: 'CommercialField',
    COMMERCIALFIELD_SEARCH: "CommercialField?sortBy={sortBy}&sortOrder={sortOrder}&searchKey={searchKey}",
    COMMERCIALFIELD_DETAIL: 'CommercialField/{id}',
    COMMERCIALFIELD_CREATE: "CommercialField",
    COMMERCIALFIELD_UPDATE: "CommercialField/{id}",
    COMMERCIALFIELD_UPDATE_LIST: "CommercialField",
    COMMERCIALFIELD_DELETE: "CommercialField/{id}",
    COMMERCIALFIELD_GET_HIDE: 'CommercialField?sortBy={sortBy}&sortOrder={sortOrder}&searchKey={searchKey}&show=0',
    COMMERCIALFIELD_GET_SHOW: 'CommercialField?sortBy={sortBy}&sortOrder={sortOrder}&searchKey={searchKey}&show=1',

     // COMMERCIALFIELD
     ENERGYFIELD_LIST: 'EnergyField',
     ENERGYFIELD_SEARCH: "EnergyField?sortBy={sortBy}&sortOrder={sortOrder}&searchKey={searchKey}",
     ENERGYFIELD_DETAIL: 'EnergyField/{id}',
     ENERGYFIELD_CREATE: "EnergyField",
     ENERGYFIELD_UPDATE: "EnergyField/{id}",
     ENERGYFIELD_UPDATE_LIST: "EnergyField",
     ENERGYFIELD_DELETE: "EnergyField/{id}",
     ENERGYFIELD_GET_HIDE: 'EnergyField?sortBy={sortBy}&sortOrder={sortOrder}&searchKey={searchKey}&show=0',
     ENERGYFIELD_GET_SHOW: 'EnergyField?sortBy={sortBy}&sortOrder={sortOrder}&searchKey={searchKey}&show=1',

    // INS TRADE SECTOR
    INSTRADESECTOR_LIST: 'IndustryTradeSector',
    INSTRADESECTOR_SEARCH: "IndustryTradeSector?sortBy={sortBy}&sortOrder={sortOrder}&searchKey={searchKey}",
    INSTRADESECTOR_DETAIL: 'IndustryTradeSector/{id}',
    INSTRADESECTOR_CREATE: "IndustryTradeSector",
    INSTRADESECTOR_UPDATE: "IndustryTradeSector/{id}",
    INSTRADESECTOR_DELETE: "IndustryTradeSector/{id}",
    INSTRADESECTOR_GET_HIDE: 'IndustryTradeSector/hide/{userId}?sortBy={sortBy}&sortOrder={sortOrder}&searchKey={searchKey}',
    INSTRADESECTOR_GET_SHOW: 'IndustryTradeSector/show/{userId}?sortBy={sortBy}&sortOrder={sortOrder}&searchKey={searchKey}',

    // INS TRADE TARGET
    INSTRADETARGET_LIST: 'IndustryTradeTarget?sortBy={sortBy}&sortOrder={sortOrder}&searchKey={searchKey}',
    INSTRADETARGET_SEARCH: "IndustryTradeTarget?sortBy={sortBy}&sortOrder={sortOrder}&searchKey={searchKey}",
    INSTRADETARGET_CREATE: "IndustryTradeTarget",
    INSTRADETARGET_UPDATE: "IndustryTradeTarget/{id}",
    INSTRADETARGET_DELETE: "IndustryTradeTarget/{id}",
    INSTRADETARGET_GET_BY_ID: "IndustryTradeTarget/{id}",
    INSTRADETARGET_GET_BY_PARENTCODE: "IndustryTradeTarget/of/{id}",
    INSTRADETARGET_GET_HIDE: 'IndustryTradeTarget/hide/{userId}?sortBy={sortBy}&sortOrder={sortOrder}&searchKey={searchKey}',
    INSTRADETARGET_GET_SHOW: 'IndustryTradeTarget/show/{userId}?sortBy={sortBy}&sortOrder={sortOrder}&searchKey={searchKey}',
    INSTRADETARGET_GET_CHILDREN: 'IndustryTradeTarget/children/{parentId}?sortBy={sortBy}&sortOrder={sortOrder}&searchKey={searchKey}',

    // PRODUCT OF INDUSTRY
    PRODUCTOFINDUSTRY_LIST: 'ProductOfIndustry?sortBy={sortBy}&sortOrder={sortOrder}&searchKey={searchKey}',
    PRODUCTOFINDUSTRY_SEARCH: "ProductOfIndustry?sortBy={sortBy}&sortOrder={sortOrder}&searchKey={searchKey}",
    PRODUCTOFINDUSTRY_CREATE: "ProductOfIndustry",
    PRODUCTOFINDUSTRY_UPDATE: "ProductOfIndustry/{id}",
    PRODUCTOFINDUSTRY_DELETE: "ProductOfIndustry/{id}",
    PRODUCTOFINDUSTRY_GET_BY_ID: "ProductOfIndustry/{id}",
    PRODUCTOFINDUSTRY_GET_BY_PARENTID: "ProductOfIndustry/of/{id}",
    PRODUCTOFINDUSTRY_GET_HIDE: 'ProductOfIndustry/hide/{userId}?sortBy={sortBy}&sortOrder={sortOrder}&searchKey={searchKey}',
    PRODUCTOFINDUSTRY_GET_SHOW: 'ProductOfIndustry/show/{userId}?sortBy={sortBy}&sortOrder={sortOrder}&searchKey={searchKey}',
    PRODUCTOFINDUSTRY_GET_CHILDREN: 'ProductOfIndustry/children/{parentId}?sortBy={sortBy}&sortOrder={sortOrder}&searchKey={searchKey}',

    // EXIMSERVICE
    EXIMSERVICE_LIST: 'ExportImportService?sortBy={sortBy}&sortOrder={sortOrder}&searchKey={searchKey}',
    EXIMSERVICE_SEARCH: "ExportImportService?sortBy={sortBy}&sortOrder={sortOrder}&searchKey={searchKey}",
    EXIMSERVICE_CREATE: "ExportImportService",
    EXIMSERVICE_UPDATE: "ExportImportService/{id}",
    EXIMSERVICE_DELETE: "ExportImportService/{id}",
    EXIMSERVICE_GET_BY_ID: "ExportImportService/{id}",
    EXIMSERVICE_GET_BY_PARENTCODE: "ExportImportService/of/{id}",
    EXIMSERVICE_GET_HIDE: 'ExportImportService/hide/{userId}?sortBy={sortBy}&sortOrder={sortOrder}&searchKey={searchKey}',
    EXIMSERVICE_GET_SHOW: 'ExportImportService/show/{userId}?sortBy={sortBy}&sortOrder={sortOrder}&searchKey={searchKey}',
    EXIMSERVICE_GET_CHILDREN: 'ExportImportService/children/{parentId}?sortBy={sortBy}&sortOrder={sortOrder}&searchKey={searchKey}',

    // PRICESTABILIZEDGOOD
    PRICESTABILIZEDGOOD_LIST: 'PriceStabilizedGoods',
    PRICESTABILIZEDGOOD_SEARCH: "PriceStabilizedGoods?sortBy={sortBy}&sortOrder={sortOrder}&searchKey={searchKey}",
    PRICESTABILIZEDGOOD_CREATE: "PriceStabilizedGoods",
    PRICESTABILIZEDGOOD_UPDATE: "PriceStabilizedGoods/{id}",
    PRICESTABILIZEDGOOD_DELETE: "PriceStabilizedGoods/{id}",
    PRICESTABILIZEDGOOD_GET_BY_ID: "PriceStabilizedGoods/{id}",
    PRICESTABILIZEDGOOD_GET_HIDE: 'PriceStabilizedGoods/hide/{userId}?sortBy={sortBy}&sortOrder={sortOrder}&searchKey={searchKey}',
    PRICESTABILIZEDGOOD_GET_SHOW: 'PriceStabilizedGoods/show/{userId}?sortBy={sortBy}&sortOrder={sortOrder}&searchKey={searchKey}',
    PRICESTABILIZEDGOOD_GET_CHILDRENT: 'PriceStabilizedGoods/children/{parentId}?sortBy={sortBy}&sortOrder={sortOrder}&searchKey={searchKey}',

    // UNIT
    UNIT_LIST: 'Unit',
    UNIT_SEARCH: "Unit?sortBy={sortBy}&sortOrder={sortOrder}&searchKey={searchKey}",
    UNIT_CREATE: "Unit",
    UNIT_UPDATE: "Unit/{id}",
    UNIT_DELETE: "Unit/{id}",
    UNIT_GET_BY_ID: "Unit/{id}",
    UNIT_GET_HIDE: 'Unit/hide/{userId}?sortBy={sortBy}&sortOrder={sortOrder}&searchKey={searchKey}',
    UNIT_GET_SHOW: 'Unit/show/{userId}?sortBy={sortBy}&sortOrder={sortOrder}&searchKey={searchKey}',

    // PRODUCTION EQUIPMENT GAS
    PRODEQPTGAS_LIST: 'ProductionEquipmentGas',
    PRODEQPTGAS_SEARCH: "ProductionEquipmentGas?sortBy={sortBy}&sortOrder={sortOrder}&searchKey={searchKey}",
    PRODEQPTGAS_DETAIL: 'ProductionEquipmentGas/{id}',
    PRODEQPTGAS_CREATE: "ProductionEquipmentGas",
    PRODEQPTGAS_UPDATE: "ProductionEquipmentGas/{id}",
    PRODEQPTGAS_DELETE: "ProductionEquipmentGas/{id}",
    PRODEQPTGAS_GET_HIDE: 'ProductionEquipmentGas/hide/{userId}?sortBy={sortBy}&sortOrder={sortOrder}&searchKey={searchKey}',
    PRODEQPTGAS_GET_SHOW: 'ProductionEquipmentGas/show/{userId}?sortBy={sortBy}&sortOrder={sortOrder}&searchKey={searchKey}',

    // PRODUCTION EQUIPMENT CHEMICAL
    PRODEQPTCHEMICAL_LIST: 'ProductionEquipmentChemical',
    PRODEQPTCHEMICAL_SEARCH: "ProductionEquipmentChemical?sortBy={sortBy}&sortOrder={sortOrder}&searchKey={searchKey}",
    PRODEQPTCHEMICAL_DETAIL: 'ProductionEquipmentChemical/{id}',
    PRODEQPTCHEMICAL_CREATE: "ProductionEquipmentChemical",
    PRODEQPTCHEMICAL_UPDATE: "ProductionEquipmentChemical/{id}",
    PRODEQPTCHEMICAL_DELETE: "ProductionEquipmentChemical/{id}",
    PRODEQPTCHEMICAL_GET_HIDE: 'ProductionEquipmentChemical/hide/{userId}?sortBy={sortBy}&sortOrder={sortOrder}&searchKey={searchKey}',
    PRODEQPTCHEMICAL_GET_SHOW: 'ProductionEquipmentChemical/show/{userId}?sortBy={sortBy}&sortOrder={sortOrder}&searchKey={searchKey}',


    // PRODUCTION EQUIPMENT WINE
    PRODEQPTWINE_LIST: 'ProductionEquipmentWine',
    PRODEQPTWINE_SEARCH: "ProductionEquipmentWine?sortBy={sortBy}&sortOrder={sortOrder}&searchKey={searchKey}",
    PRODEQPTWINE_DETAIL: 'ProductionEquipmentWine/{id}',
    PRODEQPTWINE_CREATE: "ProductionEquipmentWine",
    PRODEQPTWINE_UPDATE: "ProductionEquipmentWine/{id}",
    PRODEQPTWINE_DELETE: "ProductionEquipmentWine/{id}",
    PRODEQPTWINE_GET_HIDE: 'ProductionEquipmentWine/hide/{userId}?sortBy={sortBy}&sortOrder={sortOrder}&searchKey={searchKey}',
    PRODEQPTWINE_GET_SHOW: 'ProductionEquipmentWine/show/{userId}?sortBy={sortBy}&sortOrder={sortOrder}&searchKey={searchKey}',


    // PRODUCTION EQUIPMENT ELECTRIC
    PRODEQPTELECTRIC_LIST: 'ProductionEquipmentElectric',
    PRODEQPTELECTRIC_SEARCH: "ProductionEquipmentElectric?sortBy={sortBy}&sortOrder={sortOrder}&searchKey={searchKey}",
    PRODEQPTELECTRIC_DETAIL: 'ProductionEquipmentElectric/{id}',
    PRODEQPTELECTRIC_CREATE: "ProductionEquipmentElectric",
    PRODEQPTELECTRIC_UPDATE: "ProductionEquipmentElectric/{id}",
    PRODEQPTELECTRIC_DELETE: "ProductionEquipmentElectric/{id}",
    PRODEQPTELECTRIC_GET_HIDE: 'ProductionEquipmentElectric/hide/{userId}?sortBy={sortBy}&sortOrder={sortOrder}&searchKey={searchKey}',
    PRODEQPTELECTRIC_GET_SHOW: 'ProductionEquipmentElectric/show/{userId}?sortBy={sortBy}&sortOrder={sortOrder}&searchKey={searchKey}',


    // PRODUCTION EQUIPMENT FOOD
    PRODEQPTFOOD_LIST: 'ProductionEquipmentFood',
    PRODEQPTFOOD_SEARCH: "ProductionEquipmentFood?sortBy={sortBy}&sortOrder={sortOrder}&searchKey={searchKey}",
    PRODEQPTFOOD_DETAIL: 'ProductionEquipmentFood/{id}',
    PRODEQPTFOOD_CREATE: "ProductionEquipmentFood",
    PRODEQPTFOOD_UPDATE: "ProductionEquipmentFood/{id}",
    PRODEQPTFOOD_DELETE: "ProductionEquipmentFood/{id}",
    PRODEQPTFOOD_GET_HIDE: 'ProductionEquipmentFood/hide/{userId}?sortBy={sortBy}&sortOrder={sortOrder}&searchKey={searchKey}',
    PRODEQPTFOOD_GET_SHOW: 'ProductionEquipmentFood/show/{userId}?sortBy={sortBy}&sortOrder={sortOrder}&searchKey={searchKey}',


    // PRODUCTION EQUIPMENT PETROL
    PRODEQPTPETROL_LIST: 'ProductionEquipmentPetrol',
    PRODEQPTPETROL_SEARCH: "ProductionEquipmentPetrol?sortBy={sortBy}&sortOrder={sortOrder}&searchKey={searchKey}",
    PRODEQPTPETROL_DETAIL: 'ProductionEquipmentPetrol/{id}',
    PRODEQPTPETROL_CREATE: "ProductionEquipmentPetrol",
    PRODEQPTPETROL_UPDATE: "ProductionEquipmentPetrol/{id}",
    PRODEQPTPETROL_DELETE: "ProductionEquipmentPetrol/{id}",
    PRODEQPTPETROL_GET_HIDE: 'ProductionEquipmentPetrol/hide/{userId}?sortBy={sortBy}&sortOrder={sortOrder}&searchKey={searchKey}',
    PRODEQPTPETROL_GET_SHOW: 'ProductionEquipmentPetrol/show/{userId}?sortBy={sortBy}&sortOrder={sortOrder}&searchKey={searchKey}',

    // PRODUCTION EQUIPMENT TOBACCO
    PRODEQPTTOBACCO_LIST: 'ProductionEquipmentTobacco',
    PRODEQPTTOBACCO_SEARCH: "ProductionEquipmentTobacco?sortBy={sortBy}&sortOrder={sortOrder}&searchKey={searchKey}",
    PRODEQPTTOBACCO_DETAIL: 'ProductionEquipmentTobacco/{id}',
    PRODEQPTTOBACCO_CREATE: "ProductionEquipmentTobacco",
    PRODEQPTTOBACCO_UPDATE: "ProductionEquipmentTobacco/{id}",
    PRODEQPTTOBACCO_DELETE: "ProductionEquipmentTobacco/{id}",
    PRODEQPTTOBACCO_GET_HIDE: 'ProductionEquipmentTobacco/hide/{userId}?sortBy={sortBy}&sortOrder={sortOrder}&searchKey={searchKey}',
    PRODEQPTTOBACCO_GET_SHOW: 'ProductionEquipmentTobacco/show/{userId}?sortBy={sortBy}&sortOrder={sortOrder}&searchKey={searchKey}',

    //Depart Obj Class Ctrl
    DEPART_OBJECT_CLASS_GET_LIST:'DepartObjClass/GetObjClasses?departmentCode={code}',
    // PRODUCTION EQUIPMENT PETROL
    PRODEQPTIT_LIST: 'ProductionEquipmentIT',
    PRODEQPTIT_SEARCH: "ProductionEquipmentIT?sortBy={sortBy}&sortOrder={sortOrder}&searchKey={searchKey}",
    PRODEQPTIT_DETAIL: 'ProductionEquipmentIT/{id}',
    PRODEQPTIT_CREATE: "ProductionEquipmentIT",
    PRODEQPTIT_UPDATE: "ProductionEquipmentIT/{id}",
    PRODEQPTIT_DELETE: "ProductionEquipmentIT/{id}",
    PRODEQPTIT_GET_HIDE: 'ProductionEquipmentIT/hide/{userId}?sortBy={sortBy}&sortOrder={sortOrder}&searchKey={searchKey}',
    PRODEQPTIT_GET_SHOW: 'ProductionEquipmentIT/show/{userId}?sortBy={sortBy}&sortOrder={sortOrder}&searchKey={searchKey}',

    // FRESH FOOD
    FRESHFOOD_LIST: 'FreshFood',
    FRESHFOOD_SEARCH: "FreshFood?sortBy={sortBy}&sortOrder={sortOrder}&searchKey={searchKey}",
    FRESHFOOD_DETAIL: 'FreshFood/{id}',
    FRESHFOOD_CREATE: "FreshFood",
    FRESHFOOD_UPDATE: "FreshFood/{id}",
    FRESHFOOD_DELETE: "FreshFood/{id}",
    FRESHFOOD_GET_HIDE: 'FreshFood/hide/{userId}?sortBy={sortBy}&sortOrder={sortOrder}&searchKey={searchKey}',
    FRESHFOOD_GET_SHOW: 'FreshFood/show/{userId}?sortBy={sortBy}&sortOrder={sortOrder}&searchKey={searchKey}',

    // PRODUCT QUALITY
    PRODUCT_QUALITY_LIST: 'ProductQuality',
    PRODUCT_QUALITY_SEARCH: "ProductQuality?sortBy={sortBy}&sortOrder={sortOrder}&searchKey={searchKey}",
    PRODUCT_QUALITY_DETAIL: 'ProductQuality/{id}',
    PRODUCT_QUALITY_CREATE: "ProductQuality",
    PRODUCT_QUALITY_UPDATE: "ProductQuality/{id}",
    PRODUCT_QUALITY_DELETE: "ProductQuality/{id}",
    PRODUCT_QUALITY_GET_HIDE: 'ProductQuality/hide/{userId}?sortBy={sortBy}&sortOrder={sortOrder}&searchKey={searchKey}',
    PRODUCT_QUALITY_GET_SHOW: 'ProductQuality/show/{userId}?sortBy={sortBy}&sortOrder={sortOrder}&searchKey={searchKey}',

    // PROCESS TIME CATEGORY
    PROCESS_TIME_GET_LIST: "ProcessTime?sortBy={sortBy}&sortOrder={sortOrder}&searchKey={searchKey}",
    PROCESS_TIME_GET_BY_ID: "ProcessTime/{id}",
    PROCESS_TIME_LIST_PAGING: "ProcessTime/listPaging?pageIndex={page}&sortBy={sortBy}&sortOrder={sort}&searchKey={searchKey}",

    // ATTACHED FILE TYPE CATEGORY
    ATTACHED_FILE_GET_LIST: "AttachedFileType?sortBy={sortBy}&sortOrder={sortOrder}&searchKey={searchKey}",
    ATTACHED_FILE_GET_BY_ID: "AttachedFileType/{id}",
    ATTACHED_FILE_LIST_PAGING: "AttachedFileType/listPaging?pageIndex={page}&sortBy={sortBy}&sortOrder={sort}&searchKey={searchKey}",
    ATTACHED_FILE_CRUD: "AttachedFileType/{id}",

    // FILE STATUS
    FILE_STATUS_GET_LIST: "FileStatus?sortBy={sortBy}&sortOrder={sortOrder}&searchKey={searchKey}",
    FILE_STATUS_GET_BY_ID: "FileStatus/{id}",
    FILE_STATUS_LIST_PAGING: "FileStatus/listPaging?pageIndex={page}&sortBy={sortBy}&sortOrder={sort}&searchKey={searchKey}",
    FILE_STATUS_CREATE: "FileStatus",
    FILE_STATUS_UPDATE: "FileStatus/{id}",

    // SYNC JOBS
    SYNC_JOB_GET_LIST: "SyncJob?sortBy={sortBy}&sortOrder={sortOrder}&searchKey={searchKey}",
    SYNC_JOB_GET_BY_ID: "SyncJob/{id}",
    SYNC_JOB_LIST_PAGING: "SyncJob/listPaging?pageIndex={page}&sortBy={sortBy}&sortOrder={sort}&searchKey={searchKey}",
    SYNC_JOB_DELETE: "SyncJob/{id}",
    SYNC_JOB_CREATE: "SyncJob",
    SYNC_JOB_UPDATE: "SyncJob/{id}",
    // SYNC TASK
    SYNC_TASK_GET_LIST: "SyncTask?sortBy={sortBy}&sortOrder={sortOrder}&searchKey={searchKey}",
    SYNC_TASK_GET_BY_ID: "SyncTask/{id}",
    SYNC_TASK_LIST_PAGING: "SyncTask/listPaging?pageIndex={page}&sortBy={sortBy}&sortOrder={sort}&searchKey={searchKey}",
    SYNC_TASK_DELETE: "SyncTask/{id}",
    SYNC_TASK_CREATE: "SyncTask",
    SYNC_TASK_UPDATE: "SyncTask/{id}",
    SYNC_STATUS_GET_LIST: "SyncStatus?sortBy={sortBy}&sortOrder={sortOrder}&searchKey={searchKey}",
    
    // SYNC LOGS
    SYNC_LOG_GET_LIST: "SyncLog?sortBy={sortBy}&sortOrder={sortOrder}&searchKey={searchKey}",
    SYNC_LOG_GET_BY_ID: "SyncLog/{id}",
    SYNC_LOG_LIST_PAGING: "SyncLog/listPaging?pageIndex={pageIndex}&pageSize={pageSize}&sortBy={sortBy}&sortOrder={sortOrder}&searchKey={searchKey}",
    SYNC_LOG_DELETE: "SyncLog/{id}",
    SYNC_LOG_CREATE: "SyncLog",
    SYNC_LOG_UPDATE: "SyncLog/{id}",

    // OBJCLASS
    OBJ_CLASS_GET_LIST: "ObjClass?sortBy=code&sortOrder=asc&funcModuleCodes=FMCCA,FMITCA",

    // ZONING MANAGEMENT
    ZONING_MAP: "IndTradePlan/MapServer",
    // ITPINDUSTRIAL CLUSTER
    ZONING_INDUSTRIAL_CLUSTER_LIST: "ITPIndustrialCluster",
    ZONING_INDUSTRIAL_CLUSTER_MAP: "ITPIndustrialCluster/MapServer",

    // ITPINDUSTRIAL PARK
    ZONING_INDUSTRIAL_PARK_LIST: "ITPIndustrialPark",
    ZONING_INDUSTRIAL_PARK_MAP: "ITPIndustrialPark/MapServer",

    // CONNECT LGSP
    CONNECT_LGSP_DVCQG: "ConnectLGSP/dvcqg?service={service}",
    CONNECT_LGSP_DVCMC: "ConnectLGSP/dvcmc",
    CONNECT_LGSP_NGSP: "ConnectLGSP/ngsp?type={type}",
    // ITP Industrial Cluster
    ITP_INDUSTRIAL_CLUSTER_LIST: 'ITPIndustrialCluster?sortBy={sortBy}&sortOrder={sortOrder}&searchKey={searchKey}',
    ITP_INDUSTRIAL_CLUSTER_SEARCH: "ITPIndustrialCluster?sortBy={sortBy}&sortOrder={sortOrder}&searchKey={searchKey}",
    ITP_INDUSTRIAL_CLUSTER_DETAIL: 'ITPIndustrialCluster/{id}',
    ITP_INDUSTRIAL_CLUSTER_CREATE: "ITPIndustrialCluster",
    ITP_INDUSTRIAL_CLUSTER_UPDATE: "ITPIndustrialCluster/{id}",
    ITP_INDUSTRIAL_CLUSTER_DELETE: "ITPIndustrialCluster/{id}",
    
    // ITP Industrial Park
    ITP_INDUSTRIAL_PARK_LIST: 'ITPIndustrialPark?sortBy={sortBy}&sortOrder={sortOrder}&searchKey={searchKey}',
    ITP_INDUSTRIAL_PARK_SEARCH: "ITPIndustrialPark?sortBy={sortBy}&sortOrder={sortOrder}&searchKey={searchKey}",
    ITP_INDUSTRIAL_PARK_DETAIL: 'ITPIndustrialPark/{id}',
    ITP_INDUSTRIAL_PARK_CREATE: "ITPIndustrialPark",
    ITP_INDUSTRIAL_PARK_UPDATE: "ITPIndustrialPark/{id}",
    ITP_INDUSTRIAL_PARK_DELETE: "ITPIndustrialPark/{id}",

    // ITP Market
    ITP_MARKET_LIST: "ITPMarket?sortBy={sortBy}&sortOrder={sortOrder}&searchKey={searchKey}",
    ITP_MARKET_DETAIL: 'ITPMarket/{id}',
    ITP_MARKET_CREATE: "ITPMarket",
    ITP_MARKET_UPDATE: "ITPMarket/{id}",
    ITP_MARKET_DELETE: "ITPMarket/{id}",

    // ITP Mall
    ITP_MALL_LIST: "ITPMall?sortBy={sortBy}&sortOrder={sortOrder}&searchKey={searchKey}",
    ITP_MALL_DETAIL: 'ITPMall/{id}',
    ITP_MALL_CREATE: "ITPMall",
    ITP_MALL_UPDATE: "ITPMall/{id}",
    ITP_MALL_DELETE: "ITPMall/{id}",

        // ITP TARGET PLANNING
    ITP_TARGET_PLANNING_LIST: 'ITPTargetPlanning',
    ITP_TARGET_PLANNING_GETBYPLAN: 'ITPTargetPlanning/GetByPlan?planId={planId}&classCode={classCode}',
    ITP_TARGET_PLANNING_SEARCH: "ITPTargetPlanning?sortBy={sortBy}&sortOrder={sortOrder}&searchKey={searchKey}&planningId={planningId}&classCode={classCode}",
    ITP_TARGET_PLANNING_DETAIL: 'ITPTargetPlanning/{id}',
    ITP_TARGET_PLANNING_CREATE: "ITPTargetPlanning",
    ITP_TARGET_PLANNING_UPDATE: "ITPTargetPlanning/{id}",
    ITP_TARGET_PLANNING_DELETE: "ITPTargetPlanning/{id}",

    // ITP DIARY PLANNING
    ITP_DIARY_PLANNING_LIST: 'ITPDiaryPlanning',
    ITP_DIARY_PLANNING_GETBYPLAN: 'ITPDiaryPlanning/GetByPlan?planId={planId}&classCode={classCode}&sortBy={sortBy}&sortOrder={sortOrder}',
    ITP_DIARY_PLANNING_SEARCH: "ITPDiaryPlanning?sortBy={sortBy}&sortOrder={sortOrder}&searchKey={searchKey}&planningId={planningId}&classCode={classCode}",
    ITP_DIARY_PLANNING_DETAIL: 'ITPDiaryPlanning/{id}',
    ITP_DIARY_PLANNING_CREATE: "ITPDiaryPlanning",
    ITP_DIARY_PLANNING_UPDATE: "ITPDiaryPlanning/{id}",
    ITP_DIARY_PLANNING_DELETE: "ITPDiaryPlanning/{id}",

    // ITP DIARY TARGET PLANNING
    ITP_DIARY_TARGET_PLANNING_LIST: 'ITPDiaryTargetPlanning',
    ITP_DIARY_TARGET_PLANNING_SEARCH: "ITPDiaryTargetPlanning?sortBy={sortBy}&sortOrder={sortOrder}&searchKey={searchKey}&targetId={targetId}",
    ITP_DIARY_TARGET_PLANNING_DETAIL: 'ITPDiaryTargetPlanning/{id}',
    ITP_DIARY_TARGET_PLANNING_CREATE: "ITPDiaryTargetPlanning",
    ITP_DIARY_TARGET_PLANNING_UPDATE: "ITPDiaryTargetPlanning/{id}",
    ITP_DIARY_TARGET_PLANNING_DELETE: "ITPDiaryTargetPlanning/{id}",

    //DEPARTMENT CATEGORY
    DEPARTMENT_LIST: "Department?sortBy=name&sortOrder=asc&searchKey={searchKey}",
    DEPARTMENT_LIST_SHOW: "Department?sortBy={sortBy}&sortOrder={sortOrder}&show=1&searchKey={searchKey}",
    DEPARTMENT_DETAIL: 'Department/{id}',
    DEPARTMENT_GET_BY_PARENTID: "Department/of/{id}",
    DEPARTMENT_CREATE: 'Department',
    DEPARTMENT_UPDATE: 'Department/{id}',
    DEPARTMENT_DELETE: "Department/{id}",
    DEPARTMENT_GET_HIDE: "Department/hide/{userId}?sortBy={sortBy}&sortOrder={sortOrder}&searchKey={searchKey}",
    DEPARTMENT_GET_SHOW: "Department/show/{userId}?sortBy={sortBy}&sortOrder={sortOrder}&searchKey={searchKey}",
    //POSITION CATEGORY
    POSITION_LIST: "Position?sortBy={sortBy}&sortOrder={sortOrder}&searchKey={searchKey}",
    POSITION_DETAIL: 'Position/{id}',
    POSITION_GET_BY_PARENTID: "Position/of/{id}",
    POSITION_CREATE: 'Position',
    POSITION_UPDATE: 'Position/{id}',
    POSITION_DELETE: "Position/{id}",
    POSITION_GET_HIDE: "Position/hide/{userId}?sortBy={sortBy}&sortOrder={sortOrder}&searchKey={searchKey}",
    POSITION_GET_SHOW: "Position/show/{userId}?sortBy={sortBy}&sortOrder={sortOrder}&searchKey={searchKey}",

    //INDICATOR
    INDICATOR_REPORT_LIST: 'IndicatorReport',
    INDICATOR_REPORT_DETAIL: 'IndicatorReport/{id}',
    INDICATOR_REPORT_UPDATE: 'IndicatorReport/{id}',

    INDICATOR_PLAN_LIST: 'IndicatorPlan',
    INDICATOR_PLAN_DETAIL: 'IndicatorPlan/{id}',
    INDICATOR_PLAN_SEARCH: "IndicatorPlan?sortBy={sortBy}&sortOrder={sortOrder}&searchKey={searchKey}&type={type}&&year={year}&reportId={reportId}",
    INDICATOR_PLAN_CREATE: 'IndicatorPlan',
    INDICATOR_PLAN_UPDATE: 'IndicatorPlan/{id}',
    INDICATOR_PLAN_DELETE: "IndicatorPlan/{id}",

    INDICATOR_PLANFORECAST_DETAIL: 'IndicatorPlanForecast/{id}',
    INDICATOR_PLANFORECAST_SEARCH: "IndicatorPlanForecast?sortBy={sortBy}&sortOrder={sortOrder}&searchKey={searchKey}&planId={planId}",
    INDICATOR_PLANFORECAST_CREATE: 'IndicatorPlanForecast',
    INDICATOR_PLANFORECAST_UPDATE: 'IndicatorPlanForecast/{id}',
    INDICATOR_PLANFORECAST_DELETE: "IndicatorPlanForecast/{id}",

    INDICATOR_PLANTARGET_LIST: 'IndicatorPlanTarget',
    INDICATOR_PLANTARGET_DETAIL: 'IndicatorPlanTarget/{id}',
    INDICATOR_PLANTARGET_SEARCH: "IndicatorPlanTarget?sortBy={sortBy}&sortOrder={sortOrder}&searchKey={searchKey}&reportId={reportId}&searchType={searchType}",
    INDICATOR_PLANTARGET_CREATE: 'IndicatorPlanTarget',
    INDICATOR_PLANTARGET_UPDATE: 'IndicatorPlanTarget/{id}',
    INDICATOR_PLANTARGET_DELETE: "IndicatorPlanTarget/{id}",

    INDICATOR_TARGET_LIST: 'IndicatorTarget',
    INDICATOR_TARGET_DETAIL: 'IndicatorTarget/{id}',
    INDICATOR_TARGET_SEARCH: "IndicatorTarget?sortBy={sortBy}&sortOrder={sortOrder}&searchKey={searchKey}&parentId={parentId}&planId={planId}",
    INDICATOR_TARGET_CREATE: 'IndicatorTarget',
    INDICATOR_TARGET_UPDATE: 'IndicatorTarget/{id}',
    INDICATOR_TARGET_DELETE: "IndicatorTarget/{id}",

    INDICATOR_TIME_LIST: 'IndicatorTime',
    INDICATOR_TIME_DETAIL: 'IndicatorTime/{id}',
    INDICATOR_TIME_SEARCH: "IndicatorTime?sortBy={sortBy}&sortOrder={sortOrder}&type={type}&year={year}&month={month}",
    INDICATOR_TIME_CREATEDATA: 'IndicatorTime/CreateData?year={year}',
    INDICATOR_TIME_UPDATE: 'IndicatorTime/{id}',

    INDICATOR_YEAR_LIST: 'IndicatorYear',
    INDICATOR_YEAR_DETAIL: 'IndicatorYear/{id}',
    INDICATOR_YEAR_SEARCH: 'IndicatorYear?sortBy={sortBy}&sortOrder={sortOrder}&searchKey={searchKey}',
    INDICATOR_YEAR_CREATE: 'IndicatorYear',
    INDICATOR_YEAR_UPDATE: 'IndicatorYear/{id}',
    INDICATOR_YEAR_DELETE: "IndicatorYear/{id}",

    INDICATOR_ORIGIN_LIST: 'IndicatorOrigin',
    INDICATOR_ORTIGIN_DETAIL: 'IndicatorOrigin/{id}',
    INDICATOR_ORIGIN_CREATE: 'IndicatorOrigin',
    INDICATOR_ORIGIN_UPDATE: 'IndicatorOrigin/{id}',
    INDICATOR_ORIGIN_DELETE: "IndicatorOrigin/{id}",

    INDICATOR_ICON_LIST: 'IndicatorIcon',
    INDICATOR_ICON_DETAIL: 'IndicatorIcon/{id}',
    INDICATOR_ICON_SEARCH: "IndicatorIcon?type={type}&reportId={reportId}&sortBy={sortBy}&sortOrder={sortOrder}&searchKey={searchKey}",
    INDICATOR_ICON_CREATE: 'IndicatorIcon',
    INDICATOR_ICON_UPDATE: 'IndicatorIcon/{id}',
    INDICATOR_ICON_DELETE: "IndicatorIcon/{id}",
    INDICATOR_ICON_UPLOAD: "IndicatorIconFile/upload/{id}",
    INDICATOR_ICON_DOWNLOAD: "IndicatorIconFile/download/{id}",

    INDICATOR_VALUETARGET_DETAIL: 'IndicatorValueTarget/{id}',
    INDICATOR_VALUETARGET_SEARCH: "IndicatorValueTarget?sortBy={sortBy}&sortOrder={sortOrder}&searchKey={searchKey}&planId={planId}&targetId={targetId}&planTargetId={planTargetId}&timeId={timeId}&timeId={timeId}",
    INDICATOR_VALUETARGET_CREATE: 'IndicatorValueTarget',
    INDICATOR_VALUETARGET_UPDATE: 'IndicatorValueTarget/{id}',
    INDICATOR_VALUETARGET_DELETE: "IndicatorValueTarget/{id}",
    INDICATOR_VALUETARGET_DELETERANGE: "IndicatorValueTarget/DeleteRange?planId={planId}&timeId={timeId}",
    INDICATOR_VALUETARGET_GETTIMELIST: "IndicatorValueTarget/TimeList?sortBy={sortBy}&sortOrder={sortOrder}&planId={planId}",
    INDICATOR_VALUETARGET_GETTARGETLIST: "IndicatorValueTarget/TargetList?sortBy={sortBy}&sortOrder={sortOrder}&planId={planId}&timeId={timeId}",
    INDICATOR_VALUETARGET_GETTARGETLIST_FROMTO: "IndicatorValueTarget/TargetListFromTo?sortBy={sortBy}&sortOrder={sortOrder}&planId={planId}&fromTimeId={fromTimeId}&toTimeId={toTimeId}",
    INDICATOR_VALUETARGET_EXPORTREPORT: "IndicatorValueTarget/ExportReport?planId={planId}&timeId={timeId}",
    INDICATOR_VALUETARGET_EXPORTREPORT_FROMTO: "IndicatorValueTarget/ExportReportFromTo?planId={planId}&fromTimeId={fromTimeId}&toTimeId={toTimeId}",
    INDICATOR_VALUETARGET_EXPORTREPORTFULL: "IndicatorValueTarget/ExportReportFull?planId={planId}&timeId={timeId}",
    INDICATOR_VALUETARGET_EXPORTREPORTFULL_FROMTO: "IndicatorValueTarget/ExportReportFullFromTo?planId={planId}&fromTimeId={fromTimeId}&toTimeId={toTimeId}",

    // ITP_STAGE
    STAGE_LIST: 'Stage?sortBy={sortBy}&sortOrder={sortOrder}&searchKey={searchKey}',
    STAGE_CREATE: 'Stage',
    STAGE_UPDATE: 'Stage/{id}',
    STAGE_DELETE: "Stage/{id}",
    STAGE_DETAIL: "Stage/{id}",

    // ITP INDUSTRIAL CLUSTER DETAIL
    ITP_IND_CLT_DET_LIST: 'ITPIndustrialClusterDetail?sortBy={sortBy}&sortOrder={sortOrder}&searchKey={searchKey}&planningCode={planningCode}&planningType={planningType}',
    ITP_IND_CLT_DET_SEARCH: "ITPIndustrialClusterDetail?sortBy={sortBy}&sortOrder={sortOrder}&searchKey={searchKey}",
    ITP_IND_CLT_DET_DETAIL: 'ITPIndustrialClusterDetail/{id}',
    ITP_IND_CLT_DET_CREATE: "ITPIndustrialClusterDetail",
    ITP_IND_CLT_DET_UPDATE: "ITPIndustrialClusterDetail/{id}",
    ITP_IND_CLT_DET_DELETE: "ITPIndustrialClusterDetail/{id}",

    // ITP PARK DETAIL
    ITP_IND_PARK_DET_LIST: 'ITPIndustrialParkDetail?sortBy={sortBy}&sortOrder={sortOrder}&searchKey={searchKey}&planningCode={planningCode}&planningType={planningType}',
    ITP_IND_PARK_DET_SEARCH: "ITPIndustrialParkDetail?sortBy={sortBy}&sortOrder={sortOrder}&searchKey={searchKey}",
    ITP_IND_PARK_DET_DETAIL: 'ITPIndustrialParkDetail/{id}',
    ITP_IND_PARK_DET_CREATE: "ITPIndustrialParkDetail",
    ITP_IND_PARK_DET_UPDATE: "ITPIndustrialParkDetail/{id}",
    ITP_IND_PARK_DET_DELETE: "ITPIndustrialParkDetail/{id}",

    //
    OBJ_TYPE_GET_LIST: 'ObjType?sortBy={sortBy}&sortOrder={sortOrder}&searchKey={searchKey}',
    OBJ_TYPE_GET_LIST_BY_CLASS: 'ObjType?sortBy={sortBy}&sortOrder={sortOrder}&objClass={objClass}',
    OBJ_TYPE_OBJ_CLASS: 'ObjType/byObjClass/{objClass}',

    // Ẩn hiện
    HIDE_CATEGORY_LIST: 'HideCategory?sortBy={sortBy}&sortOrder={sortOrder}&searchKey={searchKey}',
    HIDE_CATEGORY_CREATE: 'HideCategory',
    HIDE_CATEGORY_UPDATE: 'HideCategory/{id}',
    HIDE_CATEGORY_DELETE: 'HideCategory/{id}?objClass={objClass}',

    CAREER_LIST: 'Career?sortBy={sortBy}&sortOrder={sortOrder}&searchKey={searchKey}',
    CAREER_CREATE: 'Career',
    CAREER_UPDATE: 'Career/{id}',
    CAREER_DELETE: 'Career/{id}',
    CAREER_GET_BY_ID: 'Career/{id}',

    // ITP market DETAIL
    ITP_MARKET_DET_LIST: 'ITPMarketDetail?sortBy={sortBy}&sortOrder={sortOrder}&searchKey={searchKey}&planningCode={planningCode}&planningType={planningType}',
    ITP_MARKET_DET_SEARCH: "ITPMarketDetail?sortBy={sortBy}&sortOrder={sortOrder}&searchKey={searchKey}",
    ITP_MARKET_DET_DETAIL: 'ITPMarketDetail/{id}',
    ITP_MARKET_DET_CREATE: "ITPMarketDetail",
    ITP_MARKET_DET_UPDATE: "ITPMarketDetail/{id}",
    ITP_MARKET_DET_DELETE: "ITPMarketDetail/{id}",

    // ITP Supermarket
    ITP_SUPERMARKET_LIST: "ITPSuperMarket?sortBy={sortBy}&sortOrder={sortOrder}&searchKey={searchKey}",
    ITP_SUPERMARKET_DETAIL: 'ITPSuperMarket/{id}',
    ITP_SUPERMARKET_CREATE: "ITPSuperMarket",
    ITP_SUPERMARKET_UPDATE: "ITPSuperMarket/{id}",
    ITP_SUPERMARKET_DELETE: "ITPSuperMarket/{id}",
    // ITP Supermarket DETAIL
    ITP_SUPERMARKET_DET_LIST: 'ITPSuperMarketDetail?sortBy={sortBy}&sortOrder={sortOrder}&searchKey={searchKey}&planningCode={planningCode}&planningType={planningType}',
    ITP_SUPERMARKET_DET_SEARCH: "ITPSuperMarketDetail?sortBy={sortBy}&sortOrder={sortOrder}&searchKey={searchKey}",
    ITP_SUPERMARKET_DET_DETAIL: 'ITPSuperMarketDetail/{id}',
    ITP_SUPERMARKET_DET_CREATE: "ITPSuperMarketDetail",
    ITP_SUPERMARKET_DET_UPDATE: "ITPSuperMarketDetail/{id}",
    ITP_SUPERMARKET_DET_DELETE: "ITPSuperMarketDetail/{id}",

    // ITP TRADE SECTOR
    ITP_TRADE_SECTOR_LIST: "ITPTradeSector?sortBy={sortBy}&sortOrder={sortOrder}&searchKey={searchKey}",
    ITP_TRADE_SECTOR_DETAIL: 'ITPTradeSector/{id}',
    ITP_TRADE_SECTOR_CREATE: "ITPTradeSector",
    ITP_TRADE_SECTOR_UPDATE: "ITPTradeSector/{id}",
    ITP_TRADE_SECTOR_DELETE: "ITPTradeSector/{id}",

    // ITP TRADE SECTOR DETAIL
    ITP_TRADE_SECTOR_DET_LIST: "ITPTradeSectorDetail?sortBy={sortBy}&sortOrder={sortOrder}&searchKey={searchKey}&planningCode={planningCode}&planningType={planningType}",
    ITP_TRADE_SECTOR_DET_DETAIL: 'ITPTradeSectorDetail/{id}',
    ITP_TRADE_SECTOR_DET_CREATE: "ITPTradeSectorDetail",
    ITP_TRADE_SECTOR_DET_UPDATE: "ITPTradeSectorDetail/{id}",
    ITP_TRADE_SECTOR_DET_DELETE: "ITPTradeSectorDetail/{id}",
    
    // ITP MALL
    ITP_MALL_DET_LIST: 'ITPMallDetail?sortBy={sortBy}&sortOrder={sortOrder}&searchKey={searchKey}&planningCode={planningCode}&planningType={planningType}',
    ITP_MALL_DET_DETAIL: 'ITPMallDetail/{id}',
    ITP_MALL_DET_CREATE: "ITPMallDetail",
    ITP_MALL_DET_UPDATE: "ITPMallDetail/{id}",
    ITP_MALL_DET_DELETE: "ITPMallDetail/{id}",

    // ITP LPG
    ITP_BUSINESS_LPG_LIST: "ITPBusinessLPG?sortBy={sortBy}&sortOrder={sortOrder}&searchKey={searchKey}",
    ITP_BUSINESS_LPG_DETAIL: 'ITPBusinessLPG/{id}',
    ITP_BUSINESS_LPG_CREATE: "ITPBusinessLPG",
    ITP_BUSINESS_LPG_UPDATE: "ITPBusinessLPG/{id}",
    ITP_BUSINESS_LPG_DELETE: "ITPBusinessLPG/{id}",
    // ITP LPG DETAIL
    ITP_BUSINESS_LPG_DET_LIST: 'ITPBusinessLPGDetail?sortBy={sortBy}&sortOrder={sortOrder}&searchKey={searchKey}&planningCode={planningCode}&planningType={planningType}',
    ITP_BUSINESS_LPG_DET_DETAIL: 'ITPBusinessLPGDetail/{id}',
    ITP_BUSINESS_LPG_DET_CREATE: "ITPBusinessLPGDetail",
    ITP_BUSINESS_LPG_DET_UPDATE: "ITPBusinessLPGDetail/{id}",
    ITP_BUSINESS_LPG_DET_DELETE: "ITPBusinessLPGDetail/{id}",
    
    // ITP Petrol
    ITP_PETROL_LIST: "ITPPetrol?sortBy={sortBy}&sortOrder={sortOrder}&searchKey={searchKey}",
    ITP_PETROL_DETAIL: 'ITPPetrol/{id}',
    ITP_PETROL_CREATE: "ITPPetrol",
    ITP_PETROL_UPDATE: "ITPPetrol/{id}",
    ITP_PETROL_DELETE: "ITPPetrol/{id}",
    // ITP Petrol Detail
    ITP_PETROL_DET_LIST: 'ITPPetrolDetail?sortBy={sortBy}&sortOrder={sortOrder}&searchKey={searchKey}&planningCode={planningCode}&planningType={planningType}',
    ITP_PETROL_DET_DETAIL: 'ITPPetrolDetail/{id}',
    ITP_PETROL_DET_CREATE: "ITPPetrolDetail",
    ITP_PETROL_DET_UPDATE: "ITPPetrolDetail/{id}",
    ITP_PETROL_DET_DELETE: "ITPPetrolDetail/{id}",

    // ITP Layer Form
    ITP_LAYER_FORM_LIST: "{layer_code}?sortBy={sortBy}&sortOrder={sortOrder}&searchKey={searchKey}",
    ITP_LAYER_FORM_DETAIL: '{layer_code}/{id}',
    ITP_LAYER_FORM_CREATE: "{layer_code}",
    ITP_LAYER_FORM_UPDATE: "{layer_code}/{id}",
    ITP_LAYER_FORM_DELETE: "{layer_code}/{id}",

    // ITP Electricity Energy
    ITP_ELECTRICITY_ENERGY_LIST: "ITPElectricityEnergy?sortBy={sortBy}&sortOrder={sortOrder}&searchKey={searchKey}",
    ITP_ELECTRICITY_ENERGY_DETAIL: 'ITPElectricityEnergy/{id}',
    ITP_ELECTRICITY_ENERGY_CREATE: "ITPElectricityEnergy",
    ITP_ELECTRICITY_ENERGY_UPDATE: "ITPElectricityEnergy/{id}",
    ITP_ELECTRICITY_ENERGY_DELETE: "ITPElectricityEnergy/{id}",

    // ITP Electricity Energy Detail
    ITP_ELECTRICITY_ENERGY_DET_LIST: 'ITPElectricityEnergyDetail?sortBy={sortBy}&sortOrder={sortOrder}&searchKey={searchKey}&planningCode={planningCode}',
    ITP_ELECTRICITY_ENERGY_DET_SEARCH: "ITPElectricityEnergyDetail?sortBy={sortBy}&sortOrder={sortOrder}&searchKey={searchKey}",
    ITP_ELECTRICITY_ENERGY_DET_DETAIL: 'ITPElectricityEnergyDetail/byId/{id}',
    ITP_ELECTRICITY_ENERGY_DET_CREATE: "ITPElectricityEnergyDetail/{subClass}",
    ITP_ELECTRICITY_ENERGY_DET_UPDATE: "ITPElectricityEnergyDetail/{subClass}/{id}",
    ITP_ELECTRICITY_ENERGY_DET_DELETE: "ITPElectricityEnergyDetail/byId/{id}",

    // ITP Logistic
    ITP_LOGISTIC_LIST: "ITPLogistic?sortBy={sortBy}&sortOrder={sortOrder}&searchKey={searchKey}",
    ITP_LOGISTIC_DETAIL: 'ITPLogistic/{id}',
    ITP_LOGISTIC_CREATE: "ITPLogistic",
    ITP_LOGISTIC_UPDATE: "ITPLogistic/{id}",
    ITP_LOGISTIC_DELETE: "ITPLogistic/{id}",

    // ITP Logistic Detail
    ITP_LOGISTIC_DET_LIST: 'ITPLogisticDetail?sortBy={sortBy}&sortOrder={sortOrder}&searchKey={searchKey}&planningCode={planningCode}&planningType={planningType}',
    ITP_LOGISTIC_DET_SEARCH: "ITPLogisticDetail?sortBy={sortBy}&sortOrder={sortOrder}&searchKey={searchKey}",
    ITP_LOGISTIC_DET_DETAIL: 'ITPLogisticDetail/{id}',
    ITP_LOGISTIC_DET_CREATE: "ITPLogisticDetail",
    ITP_LOGISTIC_DET_UPDATE: "ITPLogisticDetail/{id}",
    ITP_LOGISTIC_DET_DELETE: "ITPLogisticDetail/{id}",

    // ITP Food
    ITP_FOOD_LIST: "ITPFood?sortBy={sortBy}&sortOrder={sortOrder}&searchKey={searchKey}",
    ITP_FOOD_DETAIL: 'ITPFood/{id}',
    ITP_FOOD_CREATE: "ITPFood",
    ITP_FOOD_UPDATE: "ITPFood/{id}",
    ITP_FOOD_DELETE: "ITPFood/{id}",

    // ITP Food Detail
    ITP_FOOD_DET_LIST: 'ITPFoodDetail?sortBy={sortBy}&sortOrder={sortOrder}&searchKey={searchKey}&planningCode={planningCode}',
    ITP_FOOD_DET_SEARCH: "ITPFoodDetail?sortBy={sortBy}&sortOrder={sortOrder}&searchKey={searchKey}",
    ITP_FOOD_DET_DETAIL: 'ITPFoodDetail/{id}',
    ITP_FOOD_DET_CREATE: "ITPFoodDetail",
    ITP_FOOD_DET_UPDATE: "ITPFoodDetail/{id}",
    ITP_FOOD_DET_DELETE: "ITPFoodDetail/{id}",

    // ITP Mining Mineral
    // TODO:git-sync
    // ITP_MINING_MINERAL_LIST: "ITPFood?sortBy={sortBy}&sortOrder={sortOrder}&searchKey={searchKey}",
    ITP_MINING_MINERAL_LIST: "ITPMiningMineral?sortBy={sortBy}&sortOrder={sortOrder}&searchKey={searchKey}",
    ITP_MINING_MINERAL_DETAIL: 'ITPMiningMineral/{id}',
    ITP_MINING_MINERAL_CREATE: "ITPMiningMineral",
    ITP_MINING_MINERAL_UPDATE: "ITPMiningMineral/{id}",
    ITP_MINING_MINERAL_DELETE: "ITPMiningMineral/{id}",

    // ITP Mining Mieral Detail
    ITP_MINING_MINERAL_DET_LIST: 'ITPMiningMineralDetail?sortBy={sortBy}&sortOrder={sortOrder}&searchKey={searchKey}&planningCode={planningCode}',
    ITP_MINING_MINERAL_DET_SEARCH: "ITPMiningMineralDetail?sortBy={sortBy}&sortOrder={sortOrder}&searchKey={searchKey}",
    ITP_MINING_MINERAL_DET_DETAIL: 'ITPMiningMineralDetail/{id}',
    ITP_MINING_MINERAL_DET_CREATE: "ITPMiningMineralDetail",
    ITP_MINING_MINERAL_DET_UPDATE: "ITPMiningMineralDetail/{id}",
    ITP_MINING_MINERAL_DET_DELETE: "ITPMiningMineralDetail/{id}",

    // ITP Chemistry Explosive
    ITP_CHEMISTRY_EXPLOSIVE_LIST: "ITPChemistryExplosive?sortBy={sortBy}&sortOrder={sortOrder}&searchKey={searchKey}",
    ITP_CHEMISTRY_EXPLOSIVE_DETAIL: 'ITPChemistryExplosive/{id}',
    ITP_CHEMISTRY_EXPLOSIVE_CREATE: "ITPChemistryExplosive",
    ITP_CHEMISTRY_EXPLOSIVE_UPDATE: "ITPChemistryExplosive/{id}",
    ITP_CHEMISTRY_EXPLOSIVE_DELETE: "ITPChemistryExplosive/{id}",

    // ITP Chemistry Explosive Detail
    ITP_CHEMISTRY_EXPLOSIVE_DET_LIST: 'ITPChemistryExplosiveDetail?sortBy={sortBy}&sortOrder={sortOrder}&searchKey={searchKey}&planningCode={planningCode}',
    ITP_CHEMISTRY_EXPLOSIVE_DET_SEARCH: "ITPChemistryExplosiveDetail?sortBy={sortBy}&sortOrder={sortOrder}&searchKey={searchKey}",
    ITP_CHEMISTRY_EXPLOSIVE_DET_DETAIL: 'ITPChemistryExplosiveDetail/{id}',
    ITP_CHEMISTRY_EXPLOSIVE_DET_CREATE: "ITPChemistryExplosiveDetail",
    ITP_CHEMISTRY_EXPLOSIVE_DET_UPDATE: "ITPChemistryExplosiveDetail/{id}",
    ITP_CHEMISTRY_EXPLOSIVE_DET_DELETE: "ITPChemistryExplosiveDetail/{id}",

    // ITP MECH METAL 
    ITP_MECH_METAL_LIST: "ITPMechMetal?sortBy={sortBy}&sortOrder={sortOrder}&searchKey={searchKey}",
    ITP_MECH_METAL_DETAIL: 'ITPMechMetal/{id}',
    ITP_MECH_METAL_CREATE: "ITPMechMetal",
    ITP_MECH_METAL_UPDATE: "ITPMechMetal/{id}",
    ITP_MECH_METAL_DELETE: "ITPMechMetal/{id}",

    // ITP Logistic Detail
    ITP_MECH_METAL_DET_LIST: 'ITPMechMetalDetail?sortBy={sortBy}&sortOrder={sortOrder}&searchKey={searchKey}&planningCode={planningCode}',
    ITP_MECH_METAL_DET_SEARCH: "ITPMechMetalDetail?sortBy={sortBy}&sortOrder={sortOrder}&searchKey={searchKey}",
    ITP_MECH_METAL_DET_DETAIL: 'ITPMechMetalDetail/{id}',
    ITP_MECH_METAL_DET_CREATE: "ITPMechMetalDetail",
    ITP_MECH_METAL_DET_UPDATE: "ITPMechMetalDetail/{id}",
    ITP_MECH_METAL_DET_DELETE: "ITPMechMetalDetail/{id}",

    ENTERPRISE_LIST: 'EnterprisesDPI?sortBy={sortBy}&sortOrder={sortOrder}&searchKey={searchKey}',
    ENTERPRISE_LIST_PAGING: 'EnterprisesDPI/listPaging?pageIndex={page}&sortBy={sortBy}&sortOrder={sort}&searchKey={searchKey}',
    ENTERPRISE_GET_BY_ID: 'EnterprisesDPI/{id}',
    ENTERPRISE_LIST_CRUD: 'EnterprisesDPI',

    SUPPORT_INDUSTRY_LIST: 'SupportIndustry?sortBy={sortBy}&sortOrder={sortOrder}&searchKey={searchKey}',
    SUPPORT_INDUSTRY_PARENT_LIST: 'SupportIndustry/parent?sortBy={sortBy}&sortOrder={sortOrder}&searchKey={searchKey}',
    SUPPORT_INDUSTRY_CRUD: 'SupportIndustry/{id}',
    SUPPORT_INDUSTRY_GET_CHILDREN: 'SupportIndustry/children/{parentId}?sortBy={sortBy}&sortOrder={sortOrder}&searchKey={searchKey}',

    COMMODITY_MARKET_LIST: 'CommodityMarket?sortBy={sortBy}&sortOrder={sortOrder}&searchKey={searchKey}',
    COMMODITY_MARKET_CRUD: 'CommodityMarket/{id}',
    COMMODITY_MARKET_GET_CHILDREN: 'CommodityMarket/children/{parentId}?sortBy={sortBy}&sortOrder={sortOrder}&searchKey={searchKey}',

    //doc old
    GET_LIST_DOC_FILE_OLD_NOT_PAG:'PBOldDocFile?departmentCode={departmentCode}',

    GET_LIST_DOC_ENT_OLD_V2:'PBOldDocFile/listPaging?pageIndex={page}&pageSize={pageSize}&sortBy={sortBy}&sortOrder={sort}&searchKey={searchKey}&objClassCode={objClassCode}&businessCode={businessCode}',
    GET_LIST_DOC_SUB_ENT_OLD_V2:'PBOldDocFile/listPaging?pageIndex={page}&pageSize={pageSize}&sortBy={sortBy}&sortOrder={sort}&searchKey={searchKey}&objClassCode={objClassCode}&parentCode={parentCode}',
    GET_LIST_DOC_ENT_OLD:'PBOldDocFile/listPaging?pageIndex={page}&sortBy={sortBy}&sortOrder={sort}&searchKey={searchKey}&departmentCode={departmentCode}&admstrProcedSectorCode={admsProcedSectorCode}&typeCode={typeCode}',
    GET_LIST_DOC_FILE_OLD:'PBOldDocFile/listPaging',
    GET_DOC_By_ID_OLD:'PBOldDocFile/{id}',
    UPLOAD_DOC_FILE_OLD:'File/uploadNew',
    CREATE_DOC_FILE_OLD:'PBOldDocFile',

    GET_TYPE_FILE: 'SysFileType?Code={code}',

    // Chemistry
    CHEMISTRY_CRUD: 'Chemistry/{id}',
    CHEMISTRY_GET_BY_CAS: 'Chemistry/byCAS/{cas}',
    CHEMISTRY_LIST: "Chemistry?sortBy={sortBy}&sortOrder={sortOrder}&searchKey={searchKey}",
    CHEMISTRY_LIST_PAGING: "Chemistry/listPaging?pageIndex={pageIndex}&pageSize={pageSize}&sortBy={sortBy}&sortOrder={sortOrder}&searchKey={searchKey}",

    // PetrolStation
    PETROL_STATION_LIST_PAGING: "PetrolStationTank/listPaging",
    PETROL_STATION_CREATE: "PetrolStationTank",
    PETROL_STATION_DETAIL: "PetrolStationTank/{id}", 
    PETROL_STATION_UPDATE: "PetrolStationTank/{id}",
    PETROL_STATION_DELETE: "PetrolStationTank/{id}",
}
