import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HTTP_INTERCEPTORS,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { LoaderService } from '../services/loader/loader.service';

@Injectable()
export class LoaderInterceptor implements HttpInterceptor {
  private readonly NOT_SHOW_LOADER_URL = [
    'NotificationLog/ofMine/listPaging',
    'assets/i18n/vi.json',
  ];
  requestEventCount = 0;

  constructor(private _loaderService: LoaderService) {}

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    const isNotShowLoader = this.NOT_SHOW_LOADER_URL.some((url) =>
      request.url.includes(url)
    );
    if (isNotShowLoader) return next.handle(request);
    this._loaderService.show();
    this.requestEventCount++;

    return next.handle(request).pipe(
      finalize(() => {
        this.requestEventCount =
          this.requestEventCount > 0 ? this.requestEventCount - 1 : 0;
        if (this.requestEventCount === 0) {
          this._loaderService.hide();
        }
      })
    );
  }
}

/**
 * Provider POJO for the interceptor
 */
export const LoaderInterceptorProvider = {
  provide: HTTP_INTERCEPTORS,
  useClass: LoaderInterceptor,
  multi: true,
};
