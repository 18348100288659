import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from '@core/services/auth/auth.service';
import { CacheAll } from '@core/lib/cache';
import { CacheConst } from '@app/core/constant/AnyConstant';
import { ApiReturnModel } from './shared/models/global.model';
import { ToastrService } from 'ngx-toastr';
import { Title } from '@angular/platform-browser';
import { GlobalVariables } from './core/constant/global-variables';
import { Idle, DEFAULT_INTERRUPTSOURCES } from '@ng-idle/core';
import { environment } from '@env/environment';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  // some fields to store our state so we can display it in the UI
  idleState = 'NOT_STARTED';
  countdown?: number = null;
  lastPing?: Date = null;

  constructor(
    private _translateService: TranslateService,
    private _titleService: Title,
    private _toastrService: ToastrService,
    private _routerService: Router,
    private _authService: AuthService,
    private _idle: Idle,
    private _cd: ChangeDetectorRef
  ) {
    _translateService.addLangs(['vi']);
    _translateService.setDefaultLang('vi');
    _titleService.setTitle(
      'Quản lý Cơ sở dữ liệu ngành Công Thương tỉnh ' +
        GlobalVariables.provinceName
    );
    this.setupIdle();
  }

  ngOnInit(): void {
    // right when the component initializes, start reset state and start watching
    this.reset();

    const link = document.querySelector("link[rel~='icon']");
    link['href'] = environment.INFORMATION.INFO_LOGO;
  }

  setupIdle() {
    // set idle parameters
    this._idle.setIdle(3600); // how long can they be inactive before considered idle, in seconds
    this._idle.setTimeout(5); // how long can they be idle before considered timed out, in seconds
    this._idle.setInterrupts(DEFAULT_INTERRUPTSOURCES); // provide sources that will "interrupt" aka provide events indicating the user is active

    // do something when the user becomes idle
    this._idle.onIdleStart.subscribe(() => {
      this.idleState = 'IDLE';
    });
    // do something when the user is no longer idle
    this._idle.onIdleEnd.subscribe(() => {
      this.idleState = 'NOT_IDLE';
      this.countdown = null;
      this._cd.detectChanges(); // how do i avoid this kludge?
    });
    // do something when the user has timed out
    this._idle.onTimeout.subscribe(() => {
      this.idleState = 'TIMED_OUT';
      const visibilityState = document.visibilityState;
      if (visibilityState === 'visible') {
        const token = CacheAll.getCookie(CacheConst.USER_TOKEN);
        const warningMessage =
          'Hệ thống tự động đăng xuất sau 1 giờ khi người dùng không thực hiện bất kỳ thao tác nào !';
        if (token) {
          this._authService.logout().subscribe(
            (rs) => {
              const result = rs as ApiReturnModel;
              if (result.code == 0) {
                this._toastrService.warning(warningMessage);
                CacheAll.deleteCookie(CacheConst.USER_TOKEN);
                CacheAll.deleteCookie(CacheConst.USER_ID);
                CacheAll.deleteCookie(CacheConst.USER_IMAGE);
                CacheAll.deleteCookie(CacheConst.USER_DETAIL);
                CacheAll.deleteCookie(CacheConst.USER_SESSION);
                CacheAll.deleteCookie(CacheConst.IS_FIRST);
                CacheAll.deleteCookie(CacheConst.IS_AGREE);
                CacheAll.deleteCookie(CacheConst.IS_AGREE_NEW);
                this._routerService.navigate(['auth'], { queryParams: {} });
              }
            },
            (error) => {
              this._toastrService.error(error.message);
            }
          );
        } else {
          this._toastrService.warning(warningMessage);
          this._routerService.navigate(['auth'], { queryParams: {} });
        }
      }
    });
    // do something as the timeout countdown does its thing
    this._idle.onTimeoutWarning.subscribe(
      (seconds) => (this.countdown = seconds)
    );
  }

  reset() {
    // we'll call this method when we want to start/reset the idle process
    // reset any component state and be sure to call idle.watch()
    this._idle.watch();
    this.idleState = 'NOT_IDLE';
    this.countdown = null;
    this.lastPing = null;
  }
}
