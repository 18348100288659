export const Constant = {
    AUTH_LOGIN: 'login',
    AUTH_RESET_PASS: 'resetPassword',
    AUTH_LOGOUT: 'logout?uid={user_id}&deid={device_id}',
    AUTH_TOKEN_VALIDATE: 'validateToken',
    AUTH_USER_PERMISSION: 'permission/byUserId',
    AUTH_REGISTER: 'register/enterprise',
    //BP PERMISSION FOR USER
    GET_BP_PERMISSON: 'PBBaseStepUser?sortBy=seqNum&sortOrder=asc&userId={userId}',
    GET_BP_PERMISSON_BY_BASE_STEP: 'PBBaseStepUser?sortBy=seqNum&sortOrder=asc&baseStepId={baseStepId}',
    GET_LIST_PAGING_BP_PERMISSON: 'PBBaseStepUser/listPaging?pageIndex={page}&sortOrder={sort}&sortBy={sortBy}&searchKey={searchKey}&userId={userId}&baseStepId={baseStepId}&departmentId={departmentId}',
    CRUD_BP_PERMISSON: 'PBBaseStepUser/{id}',
    //PROFILE USER
    USER_DETAIL: 'user/{user_id}',
    USER_UPDATE: 'user/{user_id}',
    USER_NOTIFY: 'user/notify/{notifyType}',
    USER_DELETE_ME: 'user/deleteMe',
    USER_CHANGE_PASS: 'user/changePassword/{user_id}',
    //UPLOAD
    UPLOAD: 'file/upload',
    UPLOAD_NEW: 'File/uploadNew',
    GET_TYPE_FILE: 'SysFileType?Code={code}',
    //ROLE
    ROLE_GET_LIST: "role",
    //DEPARTMENT
    DEPARTMENT_GET_LIST: "Department",
    //POSITION
    POSITION_GET_LIST: "position",
    //enterpríe
    REGISTER_ENT:"Enterprise",
    UPDATE_ENT:"Enterprise/{id}",

    //SYSTEM MANAGEMENT USER
    SM_USER_CREATE: 'user',
    SM_ENTERPRISE_USER_CREATE: 'user/enterprise',
    SM_USER_DELETE: 'user/{user_id}',
    SM_USER_UPDATE: 'user/{user_id}',
    SM_USER_DETAIL: 'user/{user_id}',
    SM_USER_GET_LIST: 'user?searchKey={searchKey}',
    SM_USER_GET_LIST_BY_DEPARTMENT_ID: 'user?searchKey={searchKey}&departmentId={departmentId}',
    SM_USER_LIST_PAGING: 'user/listPaging?pageIndex={page}&sortOrder={sort}&sortBy={sortBy}&searchKey={searchKey}',
    SM_USER_CHANGE_PASS: 'user/changePassword/{user_id}',
    SM_USER_CHANGE_ROLE: 'user/changeRole/{user_id}',
    SM_USER_CHANGE_STATUS: 'user/changeStatus/{user_id}',
    SM_USER_RESET_PASS: 'user/resetPassword/{user_id}',
    //SYSTEM MANAGEMENT USER
    SM_ENT_ACCEPT: 'enterprise/accept',
    SM_ENT_CRUD: 'enterprise/{id}',
    SM_ENT_GET_LIST: 'enterprise?searchKey={searchKey}',
    SM_ENT_LIST_PAGING: 'enterprise/listPaging?pageIndex={page}&sortOrder={sort}&sortBy={sortBy}&searchKey={searchKey}',
    SM_ENT_USER_GET_LIST: 'user/enterprise?searchKey={searchKey}',
    SM_ENT_USER_GET_LIST_PAGING: 'user/enterprise/listPaging?pageIndex={page}&sortOrder={sort}&sortBy={sortBy}&searchKey={searchKey}',
    //SYSTEM MANAGEMENT MAIL
    SM_MAIL_CREATE: 'emailConfig',
    SM_MAIL_DELETE: 'emailConfig/{id}',
    SM_MAIL_UPDATE: 'emailConfig/{id}',
    SM_MAIL_DETAIL: 'emailConfig/{id}',
    SM_MAIL_GET_LIST: 'emailConfig?searchKey={searchKey}',
    SM_MAIL_LIST_PAGING: 'emailConfig/listPaging?pageIndex={page}&sortOrder={sort}&sortBy={sortBy}&searchKey={searchKey}',
    SM_MAIL_ACTIVATE: 'emailConfig/select/{id}',
    //GROUP PERMISSION
    SM_GP_GET_LIST: 'permissionGroup?searchKey={searchKey}',
    SM_GP_LIST_PAGING: 'permissionGroup/listPaging?pageIndex={page}&sortOrder={sort}&sortBy={sortBy}&searchKey={searchKey}',
    SM_GP_CREATE: 'permissionGroup',
    SM_GP_DELETE: 'permissionGroup/{id}',
    SM_GP_UPDATE: 'permissionGroup/{id}',
    SM_GP_DETAIL: 'permissionGroup/{id}',
    //PERMISSION OBJECT
    SM_PO_GET_LIST: 'permObject?searchKey={searchKey}',
    SM_PO_LIST_PAGING: 'permObject/listPaging?pageIndex={page}&sortOrder={sort}&sortBy={sortBy}&searchKey={searchKey}',
    SM_PO_CREATE: 'permObject',
    SM_PO_DELETE: 'permObject/{id}',
    SM_PO_UPDATE: 'permObject/{id}',
    SM_PO_DETAIL: 'permObject/{id}',
    //OBJECT CLASS FOR PERMISSION
    OBJECT_CLASS_GET_LIST: 'objClass?searchKey={searchKey}&sortBy=funcModuleCode&funcModuleCodes={funcModuleCode}&hasPerms=1',
    // OBJECT_CLASS_V2_GET_LIST: 'objClassV2?searchKey={searchKey}&sortBy=funcModuleCode&funcModuleCodes={funcModuleCode}&hasPerms=1',
    OBJECT_CLASS_V2_GET_LIST: 'DynObjClass?searchKey={searchKey}&sortBy=funcModuleCode&funcModuleCodes={funcModuleCode}&hasPerms=1',
    OBJECT_CLASS_DETAIL: 'objClass/{id}',
    OBJECT_CLASS_UPDATE: 'objClass/{id}',
    OBJECT_CLASS_EXPORT_EXCEL: 'objClass/exportExcel',
    //FUNC MODULE
    FUNC_MODULE_GET_LIST: 'funcModule?sortBy=name',
    FUNC_MODULE_CRUD: 'funcModule/{id}',
    //PERMISSION
    SM_PERM_GET_LIST_BY_GP: 'permission?permissionGroupId={id}',
    SM_PERM_GET_LIST: 'permission',
    SM_PERM_CREATE: 'permission',
    SM_PERM_UPDATE: 'permission',
    SM_PERM_UPDATE_LIST: 'permission/update',
    //USER PERMISSION
    SM_UP_GET_LIST_BY_GP: 'userPermGroup?permissionGroupId={id}&searchKey={searchKey}',
    SM_UP_GET_LIST_BY_USERID: 'userPermGroup?userId={id}&searchKey={searchKey}',
    SM_UP_CREATE: 'userPermGroup',
    SM_UP_DELETE_USER_FROM_GP: 'userPermGroup/{permGroupId}/{userId}',
    SM_UP_DELETE_ALL_UP_BY_USERID: 'userPermGroup/byUserId/{userId}',
    SM_UP_DELETE_ALL_UP_BY_GPID: 'userPermGroup/byPermGroup/{permGroupId}',
    //PERMISSION AREA
    SM_PA_GET_LIST: 'PermissionArea?permissionGroupCode={permissionGroupCode}&districtCode={districtCode}&searchKey={searchKey}',
    SM_PA_GET_LIST_PAGING: 'PermissionArea?permissionGroupCode={permissionGroupCode}&districtCode={districtCode}&pageIndex={pageIndex}&pageSize={pageSize}&sortBy={sortBy}&sortOrder={sortOrder}&searchKey={searchKey}',
    SM_PA_GET_BY_USERID: 'PermissionArea/ByUserId',
    SM_PA_GET_BY_GROUP_AREA: 'PermissionArea/ByGroupVsArea?permissionGroupId={permissionGroupId}&districtId={districtId}',
    SM_PA_GET_BY_CONDITION: 'PermissionArea/Condition?permissionGroupId={permissionGroupId}&districtId={districtId}',
    SM_PA_CRUD: 'PermissionArea/{id}',
    //Business Process Step
    SM_BPS_GET_LIST: 'busiProcStep?searchKey={searchKey}',
    SM_BPS_LIST_PAGING: 'busiProcStep/listPaging?pageIndex={page}&sortOrder={sort}&sortBy={sortBy}&searchKey={searchKey}',
    SM_BPS_CREATE: 'busiProcStep',
    SM_BPS_DELETE: 'busiProcStep/{id}',
    SM_BPS_UPDATE: 'busiProcStep/{id}',
    SM_BPS_DETAIL: 'busiProcStep/{id}',
    //Business Process Recipient
    SM_BPR_CREATE: 'busiProcRcpt',
    SM_BPR_GET_LIST: 'busiProcRcpt?searchKey={searchKey}&busiProcStepId={busiProcStepId}',
    // SM_BPR_GET_LIST: 'busiProcRcpt?searchKey={searchKey}&recipientId={recipientId}&busiProcStepId={busiProcStepId}',
    SM_BPR_DELETE_BY_ID: 'busiProcRcpt/{id}',
    SM_BPR_DELETE_BY_BPS_ID: 'busiProcRcpt/byBusiProcStepId/{busiProcStepId}',
    SM_BPR_DELETE_BY_RECIPIENT_ID: 'busiProcRcpt/byRecipientId/{recipientId}',
    SM_BPR_DELETE_BY_BPS_AND_RECIPIENT_ID: 'busiProcRcpt/{busiProcStepId}/{recipientId}',
    //OBJECT CLASS FOR PERMISSION
    OBJECT_TYPE_GET_CRITERIA_BY_OBJ_CLASS: "objField/byCode?classCode={objClass}&classSubCode={objSubClass}&searchKey={searchKey}&sortOrder={sort}&sortBy={sortBy}&level=1",
    OBJECT_TYPE_GET_LIST_BY_OBJ_CLASS: 'objType/byObjClass/{objClass}?searchKey={searchKey}&sortOrder={sort}&sortBy={sortBy}',
    OBJECT_TYPE_DETAIL: 'objType/{id}',
    OBJECT_TYPE_UPDATE: 'objType/{id}',
    OBJECT_TYPE_DELETE: 'objType/{id}',
    OBJECT_TYPE_CREATE: 'objType',
    //SYSTEM LOG
    SM_LOG_GET_LIST: 'SysUserLog?searchKey={searchKey}',
    SM_LOG_LIST_PAGING: 'SysUserLog/listPaging?pageIndex={page}&sortOrder={sort}&sortBy={sortBy}&searchKey={searchKey}',
    SM_LOG_RD: 'SysUserLog/{id}',
    SM_LOG_EXPORT_EXCEL: 'SysUserLog/exportExcel',
    SM_LOG_GET_LIST_OFMINE: 'SysUserLog/ofMine?searchKey={searchKey}',
    SM_LOG_LIST_PAGING_OFMINE: 'SysUserLog/ofMine/listPaging?pageIndex={page}&sortOrder={sort}&sortBy={sortBy}&searchKey={searchKey}',
    SM_LOG_EXPORT_EXCEL_OFMINE: 'SysUserLog/ofMine/exportExcel',
    // LOG USER REQUIREMENT
    SM_LOG_USER_REQUIREMENT_GET_LIST: 'SysUserRequest?searchKey={searchKey}',
    SM_LOG_USER_REQUIREMENT_LIST_PAGING: 'SysUserRequest/listPaging?pageIndex={page}&sortOrder={sort}&sortBy={sortBy}&searchKey={searchKey}',
    SM_LOG_USER_REQUIREMENT_RD: 'SysUserRequest/{id}',
    //ACCESS LOG
    SM_ACCESS_LOG_GET_LIST: 'accessLog?searchKey={searchKey}',
    SM_ACCESS_LOG_LIST_PAGING: 'accessLog/listPaging?pageIndex={page}&sortOrder={sort}&sortBy={sortBy}&searchKey={searchKey}',
    SM_ACCESS_LOG_DETAIL: 'accessLog/{id}',
    SM_ACCESS_LOG_EXPORT_EXCEL: 'accessLog/exportExcel',
    SM_ACCESS_LOG_GET_LIST_OFMINE: 'accessLog/ofMine?searchKey={searchKey}',
    SM_ACCESS_LOG_LIST_PAGING_OFMINE: 'accessLog/ofMine/listPaging?pageIndex={page}&sortOrder={sort}&sortBy={sortBy}&searchKey={searchKey}',
    SM_ACCESS_LOG_EXPORT_EXCEL_OFMINE: 'accessLog/ofMine/exportExcel',
    //DOCUMENT
    GET_STATUS_OF_DOC:'SysStatus/byObjClass/PBDocument',
    GET_STATUS_BY_OBJCLASS:'SysStatus/byObjClass/{typeCode}',
    //TOKEN_USER
    SYS_USER_THIRD_PARTY: 'SysUserThirdParty',
    SYS_USER_THIRD_PARTY_CRUD: 'SysUserThirdParty/{id}',
    SYS_USER_THIRD_PARTY_LIST_PAGING: 'SysUserThirdParty/listPaging?pageIndex={page}&pageSize={pageSize}&searchKey={searchKey}',
    //TOKEN
    TOKEN_THIRD_PARTY_BY_USER_ID: 'TokenThirdParty/byUserId/{id}',
    TOKEN_THIRD_PARTY_CRUD: 'TokenThirdParty/{id}',
    TOKEN_THIRD_PARTY_EMAIL : 'TokenThirdParty/Mail/{id}',

    // BACKUP
    QUICK_BACKUP: 'Configuration/backup?database={database}',
    GET_BACKUP_CONFIG: 'SyncTask?sortBy={sortBy}&sortOrder={sortOrder}&searchKey=&type=backup',
    CRUD_CONFIG: 'SyncTask',
    //LGSP
    SYS_LGSP_CONNECTION: 'SysThirdParty',
    SYS_LGSP_CONNECTION_CRUD: 'SysThirdParty/{id}',
    SYS_LGSP_CONNECTION_LIST_PAGING: 'SysThirdParty/listPaging?pageIndex={page}&pageSize={pageSize}&sortBy={sortBy}&sortOrder={sortOrder}&searchKey={searchKey}',
    SYS_LGSP_CONNECTION_PERMISSION_BY_LGSP_ID:'SysThirdPartyPermission?thirdPartyId={id}',
    SYS_LGSP_CONNECTION_PERMISSION_CRUD_BY_LGSP_ID:'SysThirdPartyPermission',
    // TYPE-LGSP
    SYS_LGSP_CONNECTION_TYPE: 'ObjType/byObjClass/SysThirdParty',
    // TYPE_LIMITREQUEST-LGSP
    SYS_LGSP_CONNECTION_TYPE_LIMITREQUEST: 'ObjType/byObjClass/AccessLogThirdParty',
    //TOKEN_LGSP
    SYS_TOKEN_LGSP_CONNECTION_BY_LGSP_ID:'SysThirdPartyToken?thirdPartyId={id}&searchKey={searchKey}',
    SYS_TOKEN_LGSP_CONNECTION_LIST_PAGING_BY_LGSP_ID:'SysThirdPartyToken/listPaging?pageIndex={page}&pageSize={pageSize}&sortBy={sortBy}&sortOrder={sortOrder}&searchKey={searchKey}&thirdPartyId={id}',
    SYS_TOKEN_LGSP_CONNECTION_CRUD_BY_LGSP_ID:'SysThirdPartyToken/{id}',

    // Dashboard
    DASHBOARD_GET_LIST: 'dashboard?code={code}&permissionGroupCode={permissionGroupCode}',
    DASHBOARD_GET_LIST_BY_USER_ID: 'dashboard/ByUser?userId={userId}',
    DASHBOARD_GET_LIST_PAGING: 'dashboard/listPaging?pageIndex={pageIndex}&pageSize={pageSize}&sortBy={sortBy}&sortOrder={sortOrder}&searchKey={searchKey}',
    DASHBOARD_GET_BY_GROUP: 'dashboard/bygroup?permissionGroupId={permissionGroupId}',
    DASHBOARD_CRUD: 'dashboard/{id}',

    // UserDashboard
    USER_DASHBOARD_GET_BY_USER_ID: 'UserDashboard/ByUser/{id}',
    USER_DASHBOARD_UPSERT: 'UserDashboard/upsert',

    // Component
    DASHBOARD_COMPONENT_GET_LIST: 'component?code={code}&dashboardCode={dashboardCode}&sortBy={sortBy}&sortOrder={sortOrder}',
    DASHBOARD_COMPONENT_GET_LIST_PAGING: 'component/listPaging?pageIndex={pageIndex}&pageSize={pageSize}&sortBy={sortBy}&sortOrder={sortOrder}&searchKey={searchKey}',
    DASHBOARD_COMPONENT_GET_BY_DASHBOARD: 'component/bydashboard?dashboardId={dashboardId}',
    DASHBOARD_COMPONENT_CRUD: 'component/{id}',
    DASHBOARD_COMPONENT_GET_DATASOURCE: 'component/GetDataSource?objClassId={objClassId}&objClassType={objClassType}&objFieldId={objFieldId}&filterType={filterType}&fromDate={fromDate}&toDate={toDate}&componentType={componentType}&refresh={refresh}',

    // Component color
    DASHBOARD_COMPONENT_COLOR_GET_LIST: 'componentcolor?code={code}&dashboardCode={dashboardCode}',
    DASHBOARD_COMPONENT_COLOR_GET_LIST_PAGING: 'componentcolor/listPaging?pageIndex={pageIndex}&pageSize={pageSize}&sortBy={sortBy}&sortOrder={sortOrder}&searchKey={searchKey}',
    DASHBOARD_COMPONENT_COLOR_CRUD: 'componentcolor/{id}',

    // Component type
    DASHBOARD_COMPONENT_TYPE_GET_LIST: 'componenttype?code={code}&dashboardCode={dashboardCode}',
    DASHBOARD_COMPONENT_TYPE_GET_LIST_PAGING: 'componenttype/listPaging?pageIndex={pageIndex}&pageSize={pageSize}&sortBy={sortBy}&sortOrder={sortOrder}&searchKey={searchKey}',
    DASHBOARD_COMPONENT_TYPE_CRUD: 'componenttype/{id}',

    // Component size
    DASHBOARD_COMPONENT_SIZE_GET_LIST: 'componentsize?code={code}&dashboardCode={dashboardCode}',
    DASHBOARD_COMPONENT_SIZE_GET_LIST_PAGING: 'componentsize/listPaging?pageIndex={pageIndex}&pageSize={pageSize}&sortBy={sortBy}&sortOrder={sortOrder}&searchKey={searchKey}',
    DASHBOARD_COMPONENT_SIZE_CRUD: 'componentsize/{id}',

    // Terms
    TERMS_GET_LIST: 'terms?sortBy={sortBy}&sortOrder={sortOrder}&searchKey={searchKey}',
    TERMS_GET_LIST_PAGING: 'terms/listPaging?pageIndex={pageIndex}&pageSize={pageSize}&sortBy={sortBy}&sortOrder={sortOrder}&searchKey={searchKey}',
    TERMS_CRUD: 'terms/{id}',
    TERMS_GET_NEW: 'terms/new',

    // Privacy Policy
    PRIVACY_POLICY_GET_LIST: 'PrivacyPolicy?sortBy={sortBy}&sortOrder={sortOrder}&searchKey={searchKey}',
    PRIVACY_POLICY_GET_LIST_PAGING: 'PrivacyPolicy/listPaging?pageIndex={pageIndex}&pageSize={pageSize}&sortBy={sortBy}&sortOrder={sortOrder}&searchKey={searchKey}',
    PRIVACY_POLICY_CRUD: 'PrivacyPolicy/{id}',
    PRIVACY_POLICY_GET_NEW: 'PrivacyPolicy/new',

    // Terms user
    TERMS_USER_GET_LIST: 'TermsUser?sortBy={sortBy}&sortOrder={sortOrder}&searchKey={searchKey}&fromDate={fromDate}&toDate={toDate}&userId={userId}',
    TERMS_USER_GET_LIST_PAGING: 'TermsUser/listPaging?pageIndex={pageIndex}&pageSize={pageSize}&sortBy={sortBy}&sortOrder={sortOrder}&searchKey={searchKey}&fromDate={fromDate}&toDate={toDate}&userId={userId}',
    TERMS_USER_CRUD: 'TermsUser/{id}',
    TERMS_USER_EXIST: 'TermsUser/exist/{id}',
    TERMS_USER_EXIST_NEW: 'TermsUser/new/{id}',

    // Terms user logs
    TERMS_USER_LOGS_GET_LIST: 'TermsUserLogs?sortBy={sortBy}&sortOrder={sortOrder}&searchKey={searchKey}&fromDate={fromDate}&toDate={toDate}&userId={userId}',
    TERMS_USER_LOGS_GET_LIST_PAGING: 'TermsUserLogs/listPaging?pageIndex={pageIndex}&pageSize={pageSize}&sortBy={sortBy}&sortOrder={sortOrder}&searchKey={searchKey}&fromDate={fromDate}&toDate={toDate}&userId={userId}',
    TERMS_USER_LOGS_CRUD: 'TermsUserLogs/{id}',
    TERMS_USER_LOGS_EXIST: 'TermsUserLogs/exist/{id}',
    TERMS_USER_LOGS_EXIST_NEW: 'TermsUserLogs/new/{id}',
}